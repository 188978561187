@import '../../resources/variables/variables.scss';



@media (max-width: 768px) {
  .did-u-container {
    position: relative;
    display: flex;
    flex-direction: column;
    align-items: center;
    background-image: url('../../resources/images/didyoubg.webp');
    background-position: center;
    background-size: cover;
    min-height: 400px;

    .did-u-title-container {
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: center;
      width: 100%;
      margin-top: 6rem;
      margin-bottom: 1.5rem;
  
      .did-u-title-img {
        object-fit: cover;
        height: 3rem;
        margin: 0;
        transform: rotate(90deg);
      }
  
      .did-u-title {
        font-size: 2rem;
        font-family: $font-family;
        margin: 0;
        margin-top: -0.8rem;
        padding: 0;
        color: black;
  
        .did-u-title-color {
          color: $accent-color;
        }
      }
    }
  
    .did-u-facts-container {
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: center;
      margin-bottom: 10rem;
      height: 20rem;
      transition: 0.3s ease-in;
  
      .did-u-single-fact-container {
        display: flex;
        flex-direction: column;
        width: 80%;
  
        .did-u-single-fact-text {
          text-align: center;
          line-height: 2rem;
          font-size: 1rem;
          font-family: $accent-font-family;
          font-weight: 400;
          opacity: 0;
          transition: opacity 1s ease-in, transform 1s ease-in;
          
          &.show {
            opacity: 1;
            transform: translateX(0);
          }
        }
      }
    }
  
    .did-u-know-img {
      position: absolute;
      bottom: 0;
      object-fit: cover;
      height: auto;
      width: 100%;
    }
    
    @keyframes slideIn {
      from {
        opacity: 0;
        transform: translateX(-100%);
      }
      to {
        opacity: 1;
        transform: translateX(0);
      }
    }
  }
}

@media (min-width: 769px) {
  .did-u-container {
    position: relative;
    display: flex;
    flex-direction: column;
    align-items: center;
    height: auto;
    background-image: url('../../resources/images/bg-4.webp');
        background-size: 80% auto;
        /* Adjusted background-size property */
        background-repeat: no-repeat;
        background-position: left bottom;
        /* Adjusted background-position property */
        box-shadow: none;
        min-height: 400px;

    .did-u-title-container {
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: center;
      width: 100%;
      margin-top: 6rem;
      margin-bottom: 1.5rem;
  
      .did-u-title-img {
        object-fit: cover;
        height: 5rem;
        margin: 0;
        transform: rotate(90deg);
      }
  
      .did-u-title {
        font-size: 3rem;
        font-family: $font-family;
        margin: 0;
        margin-top: -0.8rem;
        padding: 0;
        color: $dark-color;
  
        .did-u-title-color {
          color: $accent-color;
        }
      }
    }
  
    .did-u-facts-container {
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: center;
      margin-bottom: 10rem;
      height: 20rem;
      transition: 0.3s ease-in;
  
      .did-u-single-fact-container {
        display: flex;
        flex-direction: column;
        width: 90%;
  
        .did-u-single-fact-text {
          text-align: center;
          align-self: center;
          line-height: 2.5rem;
          font-size: 1.2rem;
          font-family: $accent-font-family;
          font-weight: 300;
          opacity: 0;
          width: 80%;
          transition: opacity 1s ease-in, transform 1s ease-in;
          
          &.show {
            opacity: 1;
            transform: translateX(0);
          }
        }
      }
    }
  
    .did-u-know-img {
      position: absolute;
      bottom: 0;
      object-fit: cover;
      height: auto;
      width: 40%;

    }
    
    @keyframes slideIn {
      from {
        opacity: 0;
        transform: translateX(-100%);
      }
      to {
        opacity: 1;
        transform: translateX(0);
      }
    }
  }
}

@media (min-width: 1200px) {
  .did-u-container {
    position: relative;
    display: flex;
    flex-direction: column;
    align-items: center;
    height: auto;
    background-image: url('../../resources/images/bg-4.webp');
    background-position: left bottom;
    background-repeat: no-repeat;
    background-size: 70%;
    min-height: 400px;

    .did-u-title-container {
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: center;
      width: 100%;
      margin-top: 6rem;
      margin-bottom: 1.5rem;
  
      .did-u-title-img {
        object-fit: cover;
        height: 5rem;
        margin: 0;
        transform: rotate(90deg);
        user-select: none;
        -webkit-user-drag: none;
      }
  
      .did-u-title {
        font-size: 4rem;
        font-family: $font-family;
        margin: 0;
        margin-top: -0.8rem;
        padding: 0;
        color: $dark-color;
  
        .did-u-title-color {
          color: $accent-color;
        }
      }
    }
  
    .did-u-facts-container {
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: center;
      margin-bottom: 15rem;
      height: 20rem;
      transition: 0.3s ease-in;
  
      .did-u-single-fact-container {
        display: flex;
        flex-direction: column;
        width: 90%;
  
        .did-u-single-fact-text {
          text-align: center;
          align-self: center;
          line-height: 3rem;
          font-size: 1.5rem;
          font-family: $accent-font-family;
          font-weight: 400;
          text-wrap: wrap;
          opacity: 0;
          width: 50%;
          transition: opacity 1s ease-in, transform 1s ease-in;
          
          &.show {
            opacity: 1;
            transform: translateX(0);
          }
        }
      }
    }
  
    .did-u-know-img {
      position: absolute;
      bottom: 0;
      object-fit: cover;
      height: auto;
      width: 40%;

    }
    
    @keyframes slideIn {
      from {
        opacity: 0;
        transform: translateX(-100%);
      }
      to {
        opacity: 1;
        transform: translateX(0);
      }
    }
  }
}

