@import '../../resources/variables/variables.scss';

@media (max-width: 768px) {
    .values-container {
        display: flex;
        flex-direction: column;
        width: 100%;
        height: auto;
        min-height: 400px;
        align-items: center;
        justify-content: center;
        padding-top: 4rem;
        padding-bottom: 4rem;
        box-shadow: 0px 0px 19px 1px rgba(0, 0, 0, 0.75);
        -webkit-box-shadow: 0px 0px 19px 1px rgba(0, 0, 0, 0.75);
        -moz-box-shadow: 0px 0px 19px 1px rgba(0, 0, 0, 0.75);

        .values-title-container {
            display: flex;
            flex-direction: column;
            align-items: center;
            margin-bottom: 2rem;

            .values-title-img {
                object-fit: cover;
                width: 2rem;
                margin-bottom: -0.5rem;
                rotate: 90deg;
            }

            .values-title {
                color: black;
                font-size: 2.2rem;
                font-family: $font-family;
                margin: 0;
                padding: 0;
            }

            .values-title-color {
                color: $accent-color;
                font-family: $font-family;
                margin: 0;
                padding: 0;
            }
        }

        
        .values-singles-container {
            display: flex;
            flex-direction: column;
            align-items: center;
            justify-content: center;
            width: 100%;
            height: fit-content;

            .values-single-value-container {
                display: flex;
                flex-direction: column;
                align-items: center;
    
                background-color: rgb(244, 244, 244);
                border-radius: 0.5rem;
    
                box-shadow: 0px 0px 5px -1px rgba(0, 0, 0, 0.75);
                -webkit-box-shadow: 0px 0px 5px -1px rgba(0, 0, 0, 0.75);
                -moz-box-shadow: 0px 0px 5px -1px rgba(0, 0, 0, 0.75);
    
                width: 90%;
                padding-bottom: 2rem;
                margin-bottom: 3rem;
    
                .values-single-img {
                    object-fit: cover;
                    width: 100%;
                    margin-bottom: 1rem;
    
                    border-top-right-radius: 5px;
                    border-top-left-radius: 5px;
                }
    
                .values-single-title-container {
                    display: flex;
                    align-items: center;
    
    
                    height: 3rem;
                    width: 100%;
    
                    .values-title-single-img {
                        object-fit: cover;
                        width: 1.5rem;
                        margin-right: 0.5rem;
                        margin-left: 1.5rem;
                    }
    
                    .value-single-title {
                        font-family: $font-family;
                        font-size: 1.5rem;
                        margin: 0;
                        text-align: start;
                        width: 100%;
                        color: black;
    
                        .value-single-title-color {
                            color: $accent-color;
                        }
                    }
                }
    
                .values-single-text {
                    text-align: start;
                    font-family: $accent-font-family;
                    font-weight: 400;
                    line-height: 1.5rem;
                    font-size: 0.9rem;
                    margin: 0;
                    margin-top: 1rem;
                    width: 85%;
                }
            }
        }
    }
}

@media (min-width: 769px) {
    .values-container {
        display: flex;
        flex-direction: column;
        width: 100%;
        height: auto;
        min-height: 400px;
        align-items: center;
        padding-top: 4rem;
        padding-bottom: 4rem;
        box-shadow: none;
        background-color: #F5F5F5;

        .values-title-container {
            display: flex;
            flex-direction: column;
            align-items: center;
            margin-bottom: 3rem;

            .values-title-img {
                object-fit: cover;
                width: 2rem;
                margin-bottom: -0.5rem;
                rotate: 90deg;
            }

            .values-title {
                color: black;
                font-size: 2.2rem;
                font-family: $font-family;
                margin: 0;
                padding: 0;
            }

            .values-title-color {
                color: $accent-color;
                font-family: $font-family;
                margin: 0;
                padding: 0;
            }
        }


        .values-singles-container {
            display: flex;
            align-items: flex-start;
            justify-content: center;
            width: 80%;
            height: fit-content;

            .values-single-value-container {
                display: flex;
                flex-direction: column;
                align-items: center;

                background-color: transparent;

                box-shadow: none;

                width: 90%;
                padding-bottom: 2rem;
                margin-bottom: 3rem;

                .values-single-img {
                    display: none;
                    object-fit: cover;
                    width: 100%;
                    margin-bottom: 1rem;

                    border-top-right-radius: 5px;
                    border-top-left-radius: 5px;
                }

                .values-single-title-container {
                    display: flex;
                    align-items: center;


                    height: 3rem;
                    width: 100%;

                    .values-title-single-img {
                        object-fit: cover;
                        width: 1.5rem;
                        margin-right: 0.5rem;
                        margin-left: 0rem;
                    }

                    .value-single-title {
                        font-family: $font-family;
                        font-size: 1.2rem;
                        margin: 0;
                        text-align: start;
                        width: 100%;
                        color: black;

                        .value-single-title-color {
                            color: $accent-color;
                        }
                    }
                }

                .values-single-text {
                    text-align: start;
                    font-family: $accent-font-family;
                    font-weight: 400;
                    line-height: 1.5rem;
                    font-size: 0.9rem;
                    margin: 0;
                    margin-top: 1rem;
                    width: 85%;
                }
            }
        }
    }
}

@media (min-width: 1200px) {
    .values-container {
        display: flex;
        flex-direction: column;
        justify-content: center;
        width: 100%;
        height: auto;
        min-height: 400px;
        align-items: center;
        padding-top: 4rem;
        padding-bottom: 4rem;
        box-shadow: 0px 4px 25px 0px rgba(0, 0, 0, 0.05);
        -webkit-box-shadow: 0px 4px 25px 0px rgba(0, 0, 0, 0.05);
        -moz-box-shadow: 0px 4px 25px 0px rgba(0, 0, 0, 0.05);
        background-image: none;
        background-color: #F5F5F5;

        .values-title-container {
            display: flex;
            flex-direction: column;
            align-items: center;
            margin-bottom: 4rem;

            .values-title-img {
                object-fit: cover;
                width: 2.5rem;
                margin-bottom: -0.5rem;
                rotate: 90deg;
                user-select: none;
                -webkit-user-drag: none;
            }

            .values-title {
                color: $dark-color;
                font-size: 2.5rem;
                font-family: $font-family;
                margin: 0;
                padding: 0;
            }

            .values-title-color {
                color: $accent-color;
                font-family: $font-family;
                margin: 0;
                padding: 0;
            }
        }

        .values-singles-container {
            display: flex;
            align-items: flex-start;
            justify-content: center;
            width: 80%;
            height: fit-content;

            .values-single-value-container {
                display: flex;
                flex-direction: column;
                align-items: center;
                justify-content: flex-start;

                background-color: transparent;
                border: none;

                box-shadow: none;

                width: 30%;
                height: fit-content;
                padding-bottom: 0rem;
                margin-bottom: 0rem;

                .values-single-img {
                    display: none
                }

                .values-single-title-container {
                    display: flex;
                    align-items: center;


                    height: 3rem;
                    width: 100%;

                    .values-title-single-img {
                        object-fit: cover;
                        width: 1.5rem;
                        margin-right: 0.5rem;
                        margin-left: 1.5rem;
                        user-select: none;
                        -webkit-user-drag: none;
                    }

                    .value-single-title {
                        font-family: $font-family;
                        font-size: 1.5rem;
                        margin: 0;
                        text-align: start;
                        width: 100%;
                        color: black;

                        .value-single-title-color {
                            color: $accent-color;
                        }
                    }
                }

                .values-single-text {
                    text-align: start;
                    font-family: $accent-font-family;
                    font-weight: 400;
                    line-height: 2rem;
                    font-size: 1rem;
                    margin: 0;
                    margin-top: 0rem;
                    width: 80%;
                }
            }
        }


    }
}

@media (min-width: 1441px) {
    .values-container {
        display: flex;
        flex-direction: column;
        justify-content: center;
        width: 100%;
        height: auto;
        min-height: 400px;
        align-items: center;
        padding-top: 4rem;
        padding-bottom: 4rem;
        box-shadow: 0px 4px 25px 0px rgba(0, 0, 0, 0.05);
        -webkit-box-shadow: 0px 4px 25px 0px rgba(0, 0, 0, 0.05);
        -moz-box-shadow: 0px 4px 25px 0px rgba(0, 0, 0, 0.05);
        background-image: none;
        background-color: #F5F5F5;

        .values-title-container {
            display: flex;
            flex-direction: column;
            align-items: center;
            margin-bottom: 6rem;

            .values-title-img {
                object-fit: cover;
                width: 3rem;
                margin-bottom: -0.5rem;
                rotate: 90deg;
                user-select: none;
                -webkit-user-drag: none;
            }

            .values-title {
                color: $dark-color;
                font-size: 3rem;
                font-family: $font-family;
                margin: 0;
                padding: 0;
            }

            .values-title-color {
                color: $accent-color;
                font-family: $font-family;
                margin: 0;
                padding: 0;
            }
        }

        .values-singles-container {
            display: flex;
            align-items: flex-start;
            justify-content: center;
            width: 80%;
            height: fit-content;

            .values-single-value-container {
                display: flex;
                flex-direction: column;
                align-items: center;
                justify-content: flex-start;

                background-color: transparent;
                border: none;

                box-shadow: none;

                width: 30%;
                height: fit-content;
                padding-bottom: 0rem;
                margin-bottom: 0rem;

                .values-single-img {
                    display: none
                }

                .values-single-title-container {
                    display: flex;
                    align-items: center;


                    height: 5rem;
                    width: 100%;

                    .values-title-single-img {
                        object-fit: cover;
                        width: 1.5rem;
                        margin-right: 0.5rem;
                        margin-left: 1.5rem;
                        user-select: none;
                        -webkit-user-drag: none;
                    }

                    .value-single-title {
                        font-family: $font-family;
                        font-size: 1.5rem;
                        margin: 0;
                        text-align: start;
                        width: 100%;
                        color: black;

                        .value-single-title-color {
                            color: $accent-color;
                        }
                    }
                }

                .values-single-text {
                    text-align: start;
                    font-family: $accent-font-family;
                    font-weight: 400;
                    line-height: 2rem;
                    font-size: 1.2rem;
                    margin: 0;
                    margin-top: 0rem;
                    width: 80%;
                }
            }
        }


    }
}