// ARCHIVO DE VARIABLES SASS, SOLO MODIFICAR ESTILOS DESDE ESTE ARCHIVO.

// VARIABLES GENERALES

$font-family: 'Raleway', sans-serif;

$accent-font-family: 'Open Sans', sans-serif;

$main-color: white;

$accent-color: #43A5D4;   

$accent-color-second: #98d4b2;  
   
$dark-color: #373737; 

$background-color: #f6f6f6;

$btn-color: #43A5D4;

$btn-color-hover: #3d9cc8;

$btn-color-active: rgb(56, 147, 188);

