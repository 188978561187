@import '../../resources/variables/variables.scss';

@media (max-width: 768px) {
    .h-container {
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        height: 100vh;
        width: 100%;
        background: url('../../resources/images/home-mobile.webp') no-repeat;
        background-size: cover;
        background-position: center;
        min-height: 400px; /* Set a fixed minimum height */

        .h-title-container {
            display: flex;
            flex-direction: column;
            align-items: center;
            justify-content: center;
            width: 100%;
            margin-bottom: 2rem;

            .h-title-img {
                object-fit: cover;
                height: 10rem;
            }

            .h-title-text-container {
                display: flex;
                flex-direction: column;
                justify-content: center;
                align-items: center;
                font-family: $font-family;

                .h-title-text-title {
                    color: $main-color;
                    font-size: 3.5rem;
                    font-weight: 600;
                    margin: 0%;
                    text-shadow: 0px 0px 8px rgba(0, 0, 0, 0.6);
                }

                .h-title-text-subtitle {
                    color: $main-color;
                    font-size: 1.5rem;
                    font-weight: 400;
                    margin: 0%;
                    text-shadow: 0px 0px 8px rgba(0, 0, 0, 0.6);
                }
            }
        }

        .h-textbox {
            display: none;
        }

        .h-btn {
            display: flex;
            justify-content: center;
            align-items: center;
            color: white;
            font-size: 1.5rem;
            font-family: $font-family;
            background-color: $btn-color;
            border: none;
            border-radius: 5px;
            width: 18.7rem;
            height: 3rem;
            transition: 0.3s;

            &:hover {
                background-color: $btn-color-hover;
            }

            &:active {
                background-color: $btn-color-active;
            }
        }
    }
}

@media (min-width: 769px) {
    .h-container {
        display: flex;
        flex-direction: column;
        align-items: flex-start;
        justify-content: center;
        height: 100vh;
        width: 100%;
        background: radial-gradient(circle, rgba(44, 44, 44, 0.35) 0%, rgba(44, 44, 44, 0.35) 75%), url('../../resources/images/home-bg-img.webp') no-repeat;
        background-size: cover;

        .h-title-container {
            display: flex;
            flex-direction: row;
            align-items: center;
            justify-content: flex-start;
            width: 45rem;
            margin-left: 2rem;
            margin-top: 3rem;

            .h-title-img {
                object-fit: cover;
                height: 6rem;
                margin-right: 1rem;
            }

            .h-title-text-container {
                display: flex;
                flex-direction: column;
                justify-content: center;
                align-items: flex-start;
                font-family: $font-family;

                .h-title-text-title {
                    color: $main-color;
                    font-size: 4.5rem;
                    font-weight: 600;
                    margin: 0;
                    text-shadow: 0px 0px 8px rgba(0, 0, 0, 0.6);
                }

                .h-title-text-subtitle {
                    color: $main-color;
                    font-size: 1.5rem;
                    font-weight: 400;
                    margin: 0%;
                    text-shadow: 0px 0px 8px rgba(0, 0, 0, 0.6);
                }
            }
        }

        .h-textbox {
            color: white;
            font-family: $accent-font-family;
            font-weight: 300;
            font-size: 1rem;
            text-align: start;

            margin-left: 2rem;
            margin-bottom: 2rem;

            line-height: 2rem;
            width: 65%;
        }

        .h-btn {
            cursor: pointer;
            display: flex;
            justify-content: center;
            align-items: center;

            color: white;
            font-size: 1.2rem;
            font-weight: 500;
            font-family: $font-family;
            background-color: $btn-color;
            border: none;
            border-radius: 5px;
            width: 10rem;
            height: 3rem;
            margin-left: 2rem;
            transition: 0.3s;

            &:hover {
                background-color: $btn-color-hover;
            }

            &:active {
                background-color: $btn-color-active;
            }
        }
    }
}

@media (min-width: 1025px) {
    .h-container {
        display: flex;
        flex-direction: column;
        align-items: flex-start;
        justify-content: center;
        height: 100vh;
        width: 100%;
        background: radial-gradient(circle, rgba(44, 44, 44, 0.35) 0%, rgba(44, 44, 44, 0.35) 75%), url('../../resources/images/home-bg-img.webp') no-repeat;
        background-size: cover;

        .h-title-container {
            display: flex;
            flex-direction: row;
            align-items: center;
            justify-content: flex-start;
            width: 45rem;
            margin-left: 6rem;
            margin-top: 3rem;

            .h-title-img {
                object-fit: cover;
                height: 8rem;
                margin-right: 1rem;
            }

            .h-title-text-container {
                display: flex;
                flex-direction: column;
                justify-content: center;
                align-items: flex-start;
                font-family: $font-family;

                .h-title-text-title {
                    color: $main-color;
                    font-size: 6.5rem;
                    font-weight: 600;
                    margin: 0%;
                    text-shadow: 0px 0px 8px rgba(0, 0, 0, 0.6);
                }

                .h-title-text-subtitle {
                    color: $main-color;
                    font-size: 1.5rem;
                    font-weight: 400;
                    margin: 0%;
                    text-shadow: 0px 0px 8px rgba(0, 0, 0, 0.6);
                }
            }
        }

        .h-textbox {
            color: white;
            font-family: $accent-font-family;
            font-weight: 300;
            font-size: 1.2rem;
            text-align: start;

            margin-left: 6rem;
            margin-bottom: 2rem;

            line-height: 2rem;
            width: 65%;
        }

        .h-btn {
            cursor: pointer;
            display: flex;
            justify-content: center;
            align-items: center;

            color: white;
            font-size: 1.2rem;
            font-weight: 500;
            font-family: $font-family;
            background-color: $btn-color;
            border: none;
            border-radius: 5px;
            width: 14rem;
            height: 4rem;
            margin-left: 6rem;
            transition: 0.3s;

            &:hover {
                background-color: $btn-color-hover;
            }

            &:active {
                background-color: $btn-color-active;
            }
        }
    }
}

@media (min-width: 1400px) {
    .h-container {
        display: flex;
        flex-direction: column;
        align-items: flex-start;
        justify-content: center;
        height: 100vh;
        width: 100%;
        background: radial-gradient(circle, rgba(44, 44, 44, 0.35) 0%, rgba(44, 44, 44, 0.151) 75%), url('../../resources/images/home-bg-img.webp') no-repeat;
        background-size: cover;
        min-height: 400px; // Set a fixed minimum height

        .h-title-container {
            display: flex;
            flex-direction: row;
            align-items: center;
            justify-content: flex-start;
            width: 45rem;
            margin-left: 10rem;
            margin-top: 3rem;

            .h-title-img {
                object-fit: cover;
                height: 10rem;
                margin-right: 1rem;
            }

            .h-title-text-container {
                display: flex;
                flex-direction: column;
                justify-content: center;
                align-items: flex-start;
                font-family: $font-family;

                .h-title-text-title {
                    color: $main-color;
                    font-size: 7.5rem;
                    font-weight: 600;
                    margin: 0%;
                    text-shadow: 0px 0px 6px rgba(0, 0, 0, 0.6);
                }

                .h-title-text-subtitle {
                    color: $main-color;
                    font-size: 2.5rem;
                    font-weight: 400;
                    margin: 0%;
                    text-shadow: 0px 0px 6px rgba(0, 0, 0, 0.6);
                }
            }
        }

        .h-textbox {
            color: white;
            font-family: $accent-font-family;
            font-weight: 400;
            font-size: 1.2rem;
            text-align: start;
            letter-spacing: -0.02em;
            margin-left: 10rem;
            margin-bottom: 4rem;

            line-height: 2.5rem;
            width: 45%;
            text-shadow: 0px 0px 3px rgba(0, 0, 0, 0.6);
        }

        .h-btn {
            cursor: pointer;
            display: flex;
            justify-content: center;
            align-items: center;

            box-shadow: 0px 0px 7px -5px rgba(0,0,0,0.75);
-webkit-box-shadow: 0px 0px 7px -5px rgba(0,0,0,0.75);
-moz-box-shadow: 0px 0px 7px -5px rgba(0,0,0,0.75);
            color: white;
            background-color: $btn-color;

            font-size: 1.5rem;
            font-weight: 500;
            font-family: $font-family;

            border: none;
            border-radius: 5px;

            width: 16rem;
            height: 4rem;
            margin-left: 10rem;
            transition: 0.3s;

            &:hover {
                background-color: $btn-color-hover;
            }

            &:active {
                background-color: $btn-color-active;
            }
        }
    }
}