@import '../../resources/variables/variables.scss';

@media (max-width: 699px) {
    .abt-container {
        display: flex;
        justify-content: center;
        align-items: center;
        height: 100vh;
        width: 100%;
        min-height: 400px; /* Set a fixed minimum height */


        .abt-img-container {
            display: none;
            height: 100%;
            width: 50%;

            .abt-img {
                object-fit: cover;
                height: 100%;
            }
        }

        .abt-text-container {
            display: flex;
            flex-direction: column;
            justify-content: center;
            align-items: center;
            width: 90%;
            margin-right: 0%;

            .abt-text-title-container {
                display: flex;
                align-items: center;
                justify-content: center;
                height: 3rem;
                font-family: $font-family;
                width: 100%;
                margin-bottom: 1rem;

                .abt-text-title-img {
                    object-fit: cover;
                    height: 100%;
                    margin-right: 0.5rem;
                }

                .abt-text-title {
                    font-size: 1.7rem;
                    text-align: start;
                    margin: 0%;
                    color: $dark-color;
                }

                .abt-text-title-color {
                    color: $accent-color;
                    margin: 0%;
                }
            }

            .abt-textbox {
                align-self: center;
                text-align: center;
                font-family: $accent-font-family;
                color: $dark-color;
                font-weight: 400;
                font-size: 0.8rem;
                line-height: 1.5rem;
                width: 80%;
                margin-bottom: 2rem;
            }

            .abt-icon-rows-container {
                display: flex;
                flex-direction: column;
                justify-content: center;
                align-items: center;
                width: 90%;

                .abt-icon-row {
                    display: flex;
                    justify-content: space-between;
                    width: 90%;
                    margin-bottom: 2rem;

                    .abt-icon-container {
                        display: flex;
                        flex-direction: column;
                        width: 45%;

                        .abt-icon {
                            object-fit: contain;
                            height: 3.5rem;
                            width: auto;
                        }

                        .abt-icont-text {
                            color: $dark-color;
                            font-family: $font-family;
                            font-weight: 500;
                            font-size: 1rem;
                        }
                    }
                }
            }




            .abt-text-buttons-container {
                display: flex;
                align-self: center;
                justify-content: space-between;
                width: 91%;
                height: 2.8rem;


                .abt-text-button {
                    display: flex;
                    align-items: center;
                    justify-content: center;
                    background-color: $btn-color;
                    color: white;
                    border: none;
                    font-size: 1rem;
                    font-family: $font-family;
                    border-radius: 5px;
                    width: 42%;

                    &:hover {
                        background-color: $btn-color-hover;
                    }

                    &:active {
                        background-color: $btn-color-active;
                    }
                }
            }
        }
    }
}

@media (min-width: 700px) {
    .abt-container {
        display: flex;
        flex-direction: row-reverse;
        justify-content: center;
        align-items: center;

        height: 120vh;
        width: 100%;
        min-height: 600px; /* Adjusted minimum height */

        background-image: url('../../resources/images/bg-3.webp');
        background-size: 60% auto;
        /* Adjusted background-size property */
        background-repeat: no-repeat;
        background-position: left top;
        /* Adjusted background-position property */
        box-shadow: none;

        .abt-img-container {
            display: none;
            height: 100%;
            width: 50%;

            .abt-img {
                display: none;
                object-fit: cover;
                height: 100%;
            }
        }

        .abt-text-container {
            display: flex;
            flex-direction: column;
            justify-content: center;
            align-items: center;
            width: 80%;
            margin-right: 0%;

            .abt-text-title-container {
                display: flex;
                align-items: center;
                justify-content: center;
                height: fit-content;
                font-family: $font-family;
                width: 100%;
                margin-bottom: 2rem;

                .abt-text-title-img {
                    -webkit-user-drag: none;
                    object-fit: cover;
                    height: 3rem;
                    margin-right: 0.5rem;
                }

                .abt-text-title {
                    font-size: 2.5rem;
                    margin: 0%;
                    color: $dark-color;
                }

                .abt-text-title-color {
                    color: $accent-color;
                    margin: 0%;
                }
            }

            .abt-textbox {
                align-self: center;
                text-align: justify;

                font-family: $accent-font-family;
                font-weight: 500;

                line-height: 2rem;
                font-size: 1rem;
                width: 80%;
                color: $dark-color;
                margin-bottom: 4rem;
            }


            .abt-icon-rows-container {
                display: flex;
                justify-content: space-between;
                width: 90%;
                margin-bottom: 4rem;

                .abt-icon-row {
                    display: flex;
                    justify-content: space-between;
                    width: 50%;
                    margin-bottom: 0rem;

                    .abt-icon-container {
                        display: flex;
                        flex-direction: column;
                        width: 50%;

                        .abt-icon {
                            object-fit: contain;
                            height: 4rem;
                            width: auto;
                        }

                        .abt-icont-text {
                            color: $dark-color;
                            font-family: $accent-font-family;
                            font-weight: 500;
                            font-size: 1rem;
                        }
                    }
                }

            }

        }

        .abt-text-buttons-container {
            display: flex;
            align-self: center;
            justify-content: space-between;
            width: 35rem;
            height: 2.8rem;


            .abt-text-button {
                cursor: pointer;
                display: flex;
                align-items: center;
                justify-content: center;

                background-color: $btn-color;
                color: white;
                border: none;

                font-size: 1.2rem;
                font-family: $font-family;
                font-weight: 500;

                border-radius: 5px;
                width: 40%;
                height: 4rem;

                transition: 0.3s;

                &:hover {
                    background-color: $btn-color-hover;
                }

                &:active {
                    background-color: $btn-color-active;
                }
            }
        }
    }
}

@media (min-width: 1025px) {
    .abt-container {
        display: flex;
        flex-direction: row-reverse;
        justify-content: flex-end;
        align-items: center;
        height: 100vh;
        width: 100%;

        min-height: 800px; /* Adjusted minimum height */

        background-image: url('../../resources/images/About.webp');
        background-size: 50% auto;
        /* Adjusted background-size property */
        background-repeat: no-repeat;
        background-position: right bottom;
        /* Adjusted background-position property */
        box-shadow: 0px 0px 11px -4px rgba(0, 0, 0, 0.75);
        -webkit-box-shadow: 0px 0px 11px -4px rgba(0, 0, 0, 0.75);
        -moz-box-shadow: 0px 0px 11px -4px rgba(0, 0, 0, 0.75);

        .abt-img-container {
            display: none;
            height: 100%;
            width: 50%;

            .abt-img {
                object-fit: cover;
                height: 100%;
            }
        }

        .abt-text-container {
            display: flex;
            flex-direction: column;
            justify-content: center;
            align-items: center;
            width: 60%;
            margin-right: 0%;

            .abt-text-title-container {
                display: flex;
                align-items: center;
                justify-content: center;
                height: fit-content;
                font-family: $font-family;
                width: 100%;
                margin-bottom: 2rem;

                .abt-text-title-img {
                    -webkit-user-drag: none;
                    object-fit: cover;
                    height: 5rem;
                    margin-right: 1.5rem;
                }

                .abt-text-title {
                    font-size: 4rem;
                    margin: 0%;
                    color: $dark-color;
                }

                .abt-text-title-color {
                    color: $accent-color;
                    margin: 0%;
                }
            }

            .abt-textbox {
                align-self: center;
                text-align: justify;

                font-family: $accent-font-family;
                font-weight: 500;

                line-height: 2rem;
                font-size: 1rem;
                width: 70%;
                color: $dark-color;
                margin-bottom: 4rem;
            }


            .abt-icon-rows-container {
                display: flex;
                justify-content: space-between;
                width: 75%;
                margin-bottom: 4rem;

                .abt-icon-row {
                    display: flex;
                    justify-content: space-between;
                    width: 50%;
                    margin-bottom: 0rem;

                    .abt-icon-container {
                        display: flex;
                        flex-direction: column;
                        width: 50%;

                        .abt-icon {
                            object-fit: contain;
                            height: 4rem;
                            width: auto;
                        }

                        .abt-icont-text {
                            color: $dark-color;
                            font-family: $accent-font-family;
                            font-weight: 500;
                            font-size: 1rem;
                        }
                    }
                }

            }

        }

        .abt-text-buttons-container {
            display: flex;
            align-self: center;
            justify-content: space-between;
            width: 35rem;
            height: 2.8rem;


            .abt-text-button {
                cursor: pointer;
                display: flex;
                align-items: center;
                justify-content: center;

                background-color: $btn-color;
                color: white;
                border: none;

                font-size: 1.2rem;
                font-family: $font-family;
                font-weight: 500;

                border-radius: 5px;
                width: 40%;
                height: 4rem;

                transition: 0.3s;

                &:hover {
                    background-color: $btn-color-hover;
                }

                &:active {
                    background-color: $btn-color-active;
                }
            }
        }
    }
}

@media (min-width: 1600px) {
    .abt-container {
        display: flex;
        flex-direction: row-reverse;
        justify-content: flex-end;
        align-items: center;
        height: 100vh;
        width: 100%;

        min-height: 800px; /* Adjusted minimum height */

        background-image: url('../../resources/images/About.webp');
        background-size: 50% auto;
        /* Adjusted background-size property */
        background-repeat: no-repeat;
        background-position: right bottom;
        /* Adjusted background-position property */
        box-shadow: 0px 0px 11px -4px rgba(0, 0, 0, 0.75);
        -webkit-box-shadow: 0px 0px 11px -4px rgba(0, 0, 0, 0.75);
        -moz-box-shadow: 0px 0px 11px -4px rgba(0, 0, 0, 0.75);

        .abt-img-container {
            display: none;
            height: 100%;
            width: 50%;

            .abt-img {
                object-fit: cover;
                height: 100%;
            }
        }

        .abt-text-container {
            display: flex;
            flex-direction: column;
            justify-content: center;
            align-items: center;
            width: 60%;
            margin-right: 0%;

            .abt-text-title-container {
                display: flex;
                align-items: center;
                justify-content: center;
                height: fit-content;
                font-family: $font-family;
                width: 100%;
                margin-bottom: 2rem;

                .abt-text-title-img {
                    object-fit: cover;
                    height: 5rem;
                    margin-right: 1.5rem;
                    user-select: none;
                    -webkit-user-drag: none;
                }

                .abt-text-title {
                    font-size: 5rem;
                    margin: 0%;
                    color: $dark-color;
                }

                .abt-text-title-color {
                    color: $accent-color;
                    margin: 0%;
                }
            }

            .abt-textbox {
                align-self: center;
                text-align: justify;

                font-family: $accent-font-family;
                font-weight: 500;

                line-height: 2rem;
                font-size: 1rem;
                width: 60%;
                color: $dark-color;
                margin-bottom: 4rem;
            }


            .abt-icon-rows-container {
                display: flex;
                justify-content: space-between;
                width: 75%;
                margin-bottom: 4rem;

                .abt-icon-row {
                    display: flex;
                    justify-content: space-between;
                    width: 50%;
                    margin-bottom: 0rem;

                    .abt-icon-container {
                        display: flex;
                        flex-direction: column;
                        width: 50%;

                        .abt-icon {
                            user-select: none;
                            -webkit-user-drag: none;
                            object-fit: contain;
                            height: 6rem;
                            width: auto;
                        }

                        .abt-icont-text {
                            font-family: $accent-font-family;
                            font-weight: 600;
                            font-size: 1rem;
                        }
                    }
                }

            }

        }

        .abt-text-buttons-container {
            display: flex;
            align-self: center;
            justify-content: space-between;
            width: 60%;
            height: 2.8rem;


            .abt-text-button {
                cursor: pointer;
                display: flex;
                align-items: center;
                justify-content: center;

                box-shadow: 0px 0px 7px -3px rgba(0,0,0,0.75);
-webkit-box-shadow: 0px 0px 7px -3px rgba(0,0,0,0.75);
-moz-box-shadow: 0px 0px 7px -3px rgba(0,0,0,0.75);

                background-color: $btn-color;
                color: white;
                border: none;

                font-size: 1.2rem;
                font-family: $font-family;
                font-weight: 500;

                border-radius: 5px;
                width: 15rem;
                height: 4rem;

                transition: 0.3s;

                &:hover {
                    background-color: $btn-color-hover;
                }

                &:active {
                    background-color: $btn-color-active;
                }
            }
        }
    }
}