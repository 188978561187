@import '../../resources/variables/variables.scss';

@media (max-width: 768px) {
  .services-container {
    display: flex;
    flex-direction: column;
    align-items: center;
    background-image: url('../../resources/images/didyoubg.webp');
    background-position: center right;
    background-size: cover;

    .services-title-container {
      display: flex;
      flex-direction: column;
      align-items: center;
      width: 100%;
      margin-top: 6rem;

      .services-title-img {
        object-fit: cover;
        width: 2rem;
        transform: rotate(90deg);
      }

      .services-title {
        font-family: $font-family;
        margin: -1rem;
        color: black;
        font-size: 2rem;
      }

      .services-title-color {
        color: $accent-color;
      }
    }

    .services-square-group {
      display: flex;
      flex-direction: column;
      align-items: center;
      width: 75%;
      margin-top: 3rem;
      margin-bottom: 3rem;

      .services-square-single {
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        width: 100%;
        height: 16rem;
        margin-top: 1rem;
        margin-bottom: 1rem;
        background-color: rgb(247, 247, 247);
        border: 1px solid rgba(198, 198, 198, 0.714);
        border-radius: 5px;
        box-shadow: 0px 0px 11px -4px rgba(0, 0, 0, 0.75);
        -webkit-box-shadow: 0px 0px 11px -4px rgba(0, 0, 0, 0.75);
        -moz-box-shadow: 0px 0px 11px -4px rgba(0, 0, 0, 0.75);
        cursor: pointer;
        transition: all 0.3s ease-in; // Add a smooth transition effect

        .services-square-icon {
          object-fit: contain;
          width: 6rem;
          height: 6rem;
          transition: 0.3s;
        }

        .services-square-title {
          font-family: $font-family;
          font-size: 1.5rem;
          font-weight: 400;
          margin: 0;
          margin-bottom: 1rem;
          transition: 0.3s;
        }

        .services-square-textbox {
          font-family: $accent-font-family;
          font-size: 0.7rem;
          font-weight: 400;
          text-align: center;
          margin: 0;
          width: 80%;
          transition: 0.3s;
        }

        &.clicked {
          width: 110%; // Make it bigger on click
          height: 18rem;
        }

        &.clicked .services-square-textbox {
          font-size: 0.8rem;
        }

        &.clicked .services-square-title {
          font-size: 1.6rem;
        }

        &.clicked .services-square-icon {
          width: 7rem;
          height: 7rem;
        }
      }
    }

    .services-bottom-img {
      object-fit: contain;
      width: 100%;
    }
  }
}

@media (min-width: 769px) {
  .services-container {
    display: flex;
    flex-direction: column;
    align-items: center;
    box-shadow: 0px 4px 25px 0px rgba(0, 0, 0, 0.05);
    -webkit-box-shadow: 0px 4px 25px 0px rgba(0, 0, 0, 0.05);
    -moz-box-shadow: 0px 4px 25px 0px rgba(0, 0, 0, 0.05);
    background-color: #F5F5F5;

    .services-title-container {
      display: flex;
      flex-direction: column;
      align-items: center;
      width: 100%;
      margin-top: 3rem;

      .services-title-img {
        object-fit: cover;
        width: 2rem;
        transform: rotate(90deg);
      }

      .services-title {
        margin: -1rem;
        color: black;
        font-size: 2.5rem;
      }

      .services-title-color {
        color: $accent-color;
      }
    }

    .services-square-group {
      display: flex;
      flex-wrap: wrap;
      /* Added flex-wrap property */
      justify-content: space-between;
      /* To evenly distribute elements horizontally */
      align-items: center;
      width: 80%;
      height: 50%;
      margin-top: 3rem;
      margin-bottom: 3rem;
    }

    .services-square-single {
      flex-basis: calc(50% - 1rem);
      /* 2 elements per row with some spacing */
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: center;
      width: calc(50% - 1rem);
      /* Set width based on flex-basis */
      height: 20rem;

      margin-top: 1rem;
      margin-bottom: 1rem;

      background-color: rgb(247, 247, 247);
      border: 1px solid rgba(198, 198, 198, 0.714);
      border-radius: 5px;
      box-shadow: 0px 0px 11px -4px rgba(0, 0, 0, 0.75);
      -webkit-box-shadow: 0px 0px 11px -4px rgba(0, 0, 0, 0.75);
      -moz-box-shadow: 0px 0px 11px -4px rgba(0, 0, 0, 0.75);
      cursor: pointer;
      transition: all 0.3s ease-in;

      .services-square-icon {
        object-fit: contain;
        width: 5rem;
        height: 5rem;
        transition: 0.3s;
      }

      .services-square-title {
        font-family: $font-family;
        font-size: 1.5rem;
        font-weight: 400;
        margin: 0;
        margin-bottom: 1rem;
        transition: 0.3s;
      }

      .services-square-textbox {
        font-family: $accent-font-family;
        font-size: 0.7rem;
        font-weight: 400;
        text-align: center;
        margin: 0;
        width: 14rem;
        transition: 0.3s;
      }

      &.clicked {
        width: calc(50% - 1rem);
        /* Adjusted width on click */
        height: 21rem;
      }

      &.clicked .services-square-textbox {
        font-size: 0.7rem;
      }

      &.clicked .services-square-title {
        font-size: 1.6rem;
      }

      &.clicked .services-square-icon {
        width: 6rem;
        height: 6rem;
      }
    }


    .services-bottom-img {
      display: none;
      object-fit: contain;
      width: 100%;
    }
  }
}

@media (min-width: 1400px) {
  .services-container {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    background-color: white;
    box-shadow: none;
    padding-bottom: 0rem;
    margin-bottom: 0rem;
    background-image: url('../../resources/images/bg-2.webp');
    /* Adjusted background-size property */
    background-repeat: no-repeat;
    background-position: left;
    background-size: 65%;

    height: 100vh;
    min-height: 400px;

    .services-title-container {
      display: flex;
      flex-direction: column;
      align-items: center;
      width: 100%;
      margin-top: 6rem;

      .services-title-img {
        object-fit: cover;
        width: 3rem;
        transform: rotate(90deg);
        user-select: none;
        -webkit-user-drag: none;
      }

      .services-title {
        margin: -1rem;
        color: $dark-color;

        font-family: $font-family;
        font-size: 4rem;
      }

      .services-title-color {
        color: $accent-color;
      }
    }

    .services-square-group {
      display: flex;
      justify-content: space-between;
      align-items: center;
      width: 80%;
      margin-top: 4rem;
      margin-bottom: 0rem;
    }

    .services-square-single {
      user-select: none;
      -webkit-user-drag: none;
      flex-basis: calc(25% - 1rem);
      /* 4 elements per row with some spacing */
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: center;
      height: 20rem;
      margin-top: 1rem;
      margin-bottom: 1rem;
      background-color: rgb(247, 247, 247);
      border: 1px solid rgba(198, 198, 198, 0.714);
      border-radius: 5px;
      box-shadow: 0px 0px 11px -4px rgba(0, 0, 0, 0.75);
      -webkit-box-shadow: 0px 0px 11px -4px rgba(0, 0, 0, 0.75);
      -moz-box-shadow: 0px 0px 11px -4px rgba(0, 0, 0, 0.75);
      cursor: pointer;
      transition: all 0.3s ease-in;
      /* Add a smooth transition effect */

      .services-square-icon {
        -webkit-user-drag: none;
        user-select: none;
        object-fit: contain;
        width: 6rem;
        height: 6rem;
        transition: 0.3s;
      }

      .services-square-title {
        user-select: none;
        -webkit-user-drag: none;
        font-family: $font-family;
        font-size: 1.5rem;
        font-weight: 400;
        margin: 0;
        margin-bottom: 1rem;
        transition: 0.3s;
      }

      .services-square-textbox {
        user-select: none;
        -webkit-user-drag: none;
        font-family: $accent-font-family;
        font-size: 0.8rem;
        font-weight: 400;
        text-align: center;
        margin: 0;
        width: 14rem;
        transition: 0.3s;
      }

      &.clicked {
        flex-basis: calc(26% - 1rem);
        /* 4 elements per row with some spacing */
        height: 21rem;
      }

      &.clicked .services-square-textbox {
        font-size: 0.8rem;
      }

      &.clicked .services-square-title {
        font-size: 1.6rem;
      }

      &.clicked .services-square-icon {
        width: 7rem;
        height: 7rem;
      }
    }


    .services-bottom-img {
      display: none;
      object-fit: contain;
      width: 100%;
    }
  }
}