@import '../../resources/variables/variables.scss';

@media (max-width: 768px) {
    .footer-container {
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: space-between;
        background-color: white;
        height: auto;
        min-height: 300px;

        box-shadow: 0px 0px 11px -4px rgba(0, 0, 0, 0.75);
        -webkit-box-shadow: 0px 0px 11px -4px rgba(0, 0, 0, 0.75);
        -moz-box-shadow: 0px 0px 11px -4px rgba(0, 0, 0, 0.75);

        .footer-main-container {
            display: flex;
            flex-direction: column;
            align-items: center;
            height: 100%;
            width: 100%;

            .footer-top-container {
                display: flex;
                flex-direction: column;
                justify-content: space-evenly;
                height: 100%;
                width: 90%;
                margin-top: 2rem;
                margin-bottom: 2rem;
                color: $dark-color;
                font-family: $font-family;

                .footer-links-container {
                    display: flex;
                    flex-direction: column;
                    text-align: start;
                    justify-content: space-between;
                    height: 15rem;
                    margin-bottom: 2rem;

                    width: 90%;

                    .footer-contact-title {
                        margin: 0;
                        font-size: 1.5rem;
                        width: fit-content;

                        .footer-contact-title-color {
                            color: $accent-color;
                        }
                    }

                    .footer-link {
                        cursor: pointer;
                        display: flex;
                        align-items: center;
                        justify-content: center;

                        font-size: 1rem;
                        font-family: $font-family;
                        font-weight: 600;

                        color: $main-color;
                        background-color: $btn-color;

                        padding: 6px;
                        border-radius: 0.2rem;

                        box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
                        -webkit-box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
                        -moz-box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);

                        transition: 0.3s;
                        width: fit-content;
                    }
                }

                .footer-contact-container {
                    display: flex;
                    flex-direction: column;
                    text-align: start;
                    justify-content: space-between;
                    height: 15rem;
                    margin-bottom: 2rem;

                    .footer-contact-title {
                        margin: 0;
                        font-size: 1.5rem;
                        width: fit-content;
                    }

                    .footer-contact-single {
                        display: flex;
                        align-items: center;
                        font-family: Arial, Helvetica, sans-serif;
                        align-items: center;


                        .footer-icon {
                            margin-right: 0.8rem;
                            font-size: 1.5rem;
                            color: white;
                            background-color: $accent-color;
                            padding: 0.3rem;
                            height: 1rem;
                            width: 1rem;
                            border-radius: 50%;
                        }

                        .footer-contact-single-text {
                            font-family: $accent-font-family;
                            font-size: 1rem;
                            font-weight: 600;
                        }
                    }
                }

                .footer-iframe-container {
                    display: flex;
                    flex-direction: column;
                    text-align: start;
                    justify-content: space-between;
                    height: 25rem;
                    width: 100%;
                    margin-bottom: 4rem;

                    .footer-iframe-title {
                        text-align: start;
                        font-size: 1.5rem;
                        color: $dark-color;
                        margin: 0;

                        .footer-iframe-title-color {
                            color: $accent-color;
                        }
                    }

                    .footer-iframe {
                        border: 1px solid rgb(226, 226, 226);
                        box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
                        border-radius: 5px;
                        height: 85%;
                        width: 100%;
                    }
                }
            }



        }

        .footer-bottom-container {
            display: flex;
            align-items: center;
            justify-content: space-between;
            width: 100%;
            padding-left: 5%;
            padding-right: 5%;
            height: 5%;
            background-color: $accent-color;

            .footer-bottom-name {
                color: white;

                font-family: $font-family;
                font-weight: 500;
                font-size: 0.7rem;

                margin: 0;
                padding: 0;

                width: fit-content;
            }

            .footer-bottom-social-container {
                display: flex;
                align-items: center;
                justify-content: space-between;
                height: 100%;
                width: 20%;

                .footer-bottom-icon {
                    color: white;
                    font-size: 1rem;
                }
            }
        }

        .footer-credit-icons-container {
            display: flex;
            width: 100%;
            padding-left: 5%;
            padding-right: 5%;
            justify-content: center;
            align-items: center;
            background-color: $accent-color;
            border: none;

            .footer-credit-icons {
                display: flex;
                justify-content: flex-start;
                align-items: center;

                width: 100%;
                height: 3vh;

                color: white;

                text-decoration: none;
                text-align: center;
                font-family: $font-family;
                font-size: 0.7rem;
                border: none;
                font-weight: 500;
            }
        }
    }
}

@media (min-width: 769px) {
    .footer-container {
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: space-between;
        background-color: white;
        height: 100vh;
        width: 100%;
        padding-top: 10vh;
        border: 1px solid rgb(226, 226, 226);
        min-height: 800px;

        .footer-main-container {
            display: flex;
            flex-direction: row;
            align-items: center;
            justify-content: center;
            height: 100%;
            width: 100%;
            padding-bottom: 10vh;

            .footer-top-container {
                display: flex;
                flex-direction: column;
                align-items: center;
                justify-content: space-between;
                height: 100%;
                width: 80%;
                color: $dark-color;
                font-family: $font-family;

                .footer-top-text-container {
                    display: flex;
                    justify-content: flex-start;
                    height: 50%;
                    width: 100%;
                    margin-bottom: 10vh;

                    .footer-links-container {
                        display: flex;
                        flex-direction: column;
                        text-align: start;
                        justify-content: space-between;
                        margin-right: 10vh;

                        .footer-contact-title {
                            margin: 0;
                            font-size: 2rem;
                            width: fit-content;

                            .footer-contact-title-color {
                                color: $accent-color;
                            }
                        }

                        .footer-link {
                            cursor: pointer;
                            display: flex;
                            align-items: center;
                            justify-content: center;

                            font-size: 1rem;
                            font-family: $font-family;
                            font-weight: 600;

                            color: $main-color;
                            background-color: $btn-color;

                            padding: 6px;
                            border-radius: 0.2rem;

                            box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
                            -webkit-box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
                            -moz-box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);

                            transition: 0.3s;
                            width: fit-content;
                        }
                    }

                    .footer-contact-container {
                        display: flex;
                        flex-direction: column;
                        text-align: start;
                        justify-content: space-between;
                        height: 70%;

                        .footer-contact-title {
                            margin: 0;
                            font-size: 2rem;
                            width: fit-content;
                        }

                        .footer-contact-single {
                            display: flex;
                            align-items: center;
                            font-family: Arial, Helvetica, sans-serif;
                            align-items: center;


                            .footer-icon {
                                margin-right: 0.8rem;
                                font-size: 1.5rem;
                                color: white;
                                background-color: $accent-color;
                                padding: 0.3rem;
                                height: 1rem;
                                width: 1rem;
                                border-radius: 50%;
                            }

                            .footer-contact-single-text {
                                font-family: $accent-font-family;
                                font-size: 1rem;
                                font-weight: 600;
                                color: #555;
                                margin: 0;
                            }
                        }
                    }
                }

                .footer-iframes-container {
                    display: flex;
                    width: 100%;
                    height: 50%;

                    .footer-iframe-container {
                        display: flex;
                        flex-direction: column;
                        text-align: start;
                        justify-content: space-between;
                        height: 90%;
                        width: 50%;

                        .footer-iframe-title {
                            text-align: start;
                            font-size: 0.8rem;
                            color: $dark-color;
                            font-family: $accent-font-family;
                            margin: 0;

                            .footer-iframe-title-color {
                                color: $accent-color;
                            }
                        }

                        .footer-iframe {
                            border: 1px solid rgb(226, 226, 226);
                            box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
                            border-radius: 5px;
                            height: 85%;
                            width: 90%;
                        }
                    }
                }


            }



        }

        .footer-bottom-container {
            display: flex;
            align-items: center;
            justify-content: flex-start;
            width: 100%;
            padding-left: 10%;
            padding-right: 10%;
            height: 5%;
            background-color: $accent-color;

            .footer-bottom-name {
                color: white;

                font-family: $font-family;
                font-weight: 500;
                font-size: 0.7rem;

                margin: 0;
                margin-right: 1rem;
                padding: 0;

                width: fit-content;
            }

            .footer-bottom-social-container {
                display: flex;
                align-items: center;
                justify-content: space-between;
                height: 100%;
                width: 5%;

                .footer-bottom-icon {
                    color: white;
                    font-size: 1rem;
                }
            }
        }

        .footer-credit-icons-container {
            display: flex;
            width: 100%;
            padding-left: 10%;
            padding-right: 10%;
            justify-content: center;
            align-items: center;
            background-color: $accent-color;
            border: none;

            .footer-credit-icons {
                display: flex;
                justify-content: flex-start;
                align-items: center;

                width: 100%;
                height: 3vh;

                color: white;

                text-decoration: none;
                text-align: center;
                font-family: $font-family;
                font-size: 0.7rem;
                border: none;
                font-weight: 500;
            }
        }
    }
}

@media (min-width: 1200px) {
    .footer-container {
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: space-between;
        background-color: white;
        height: 100vh;
        width: 100%;
        padding-top: 10vh;
        border: 1px solid rgb(226, 226, 226);
        min-height: 800px;

        .footer-main-container {
            display: flex;
            flex-direction: row;
            align-items: center;
            justify-content: center;
            height: 100%;
            width: 100%;
            padding-bottom: 10vh;

            .footer-top-container {
                display: flex;
                flex-direction: column;
                align-items: center;
                justify-content: space-between;
                height: 100%;
                width: 80%;
                color: $dark-color;
                font-family: $font-family;

                .footer-top-text-container {
                    display: flex;
                    justify-content: flex-start;
                    height: 50%;
                    width: 100%;
                    margin-bottom: 10vh;

                    .footer-links-container {
                        display: flex;
                        flex-direction: column;
                        text-align: start;
                        justify-content: space-between;
                        margin-right: 10vh;

                        .footer-contact-title {
                            margin: 0;
                            font-size: 2rem;
                            width: fit-content;

                            .footer-contact-title-color {
                                color: $accent-color;
                            }
                        }

                        .footer-link {
                            cursor: pointer;
                            display: flex;
                            align-items: center;
                            justify-content: center;

                            font-size: 1rem;
                            font-family: $font-family;
                            font-weight: 600;

                            color: $main-color;
                            background-color: $btn-color;

                            padding: 6px;
                            border-radius: 0.2rem;

                            box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
                            -webkit-box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
                            -moz-box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);

                            transition: 0.3s;
                            width: fit-content;
                        }
                    }

                    .footer-contact-container {
                        display: flex;
                        flex-direction: column;
                        text-align: start;
                        justify-content: space-between;
                        height: 70%;

                        .footer-contact-title {
                            margin: 0;
                            font-size: 2rem;
                            width: fit-content;
                        }

                        .footer-contact-single {
                            display: flex;
                            align-items: center;
                            font-family: Arial, Helvetica, sans-serif;
                            align-items: center;


                            .footer-icon {
                                margin-right: 0.8rem;
                                font-size: 1.5rem;
                                color: white;
                                background-color: $accent-color;
                                padding: 0.3rem;
                                height: 1rem;
                                width: 1rem;
                                border-radius: 50%;
                            }

                            .footer-contact-single-text {
                                font-family: $accent-font-family;
                                font-size: 1rem;
                                font-weight: 600;
                                color: #555;
                                margin: 0;
                            }
                        }
                    }
                }

                .footer-iframes-container {
                    display: flex;
                    width: 100%;
                    height: 50%;

                    .footer-iframe-container {
                        display: flex;
                        flex-direction: column;
                        text-align: start;
                        justify-content: space-between;
                        height: 90%;
                        width: 50%;

                        .footer-iframe-title {
                            text-align: start;
                            font-size: 1.2rem;
                            color: $dark-color;
                            font-family: $accent-font-family;
                            margin: 0;

                            .footer-iframe-title-color {
                                color: $accent-color;
                            }
                        }

                        .footer-iframe {
                            border: 1px solid rgb(226, 226, 226);
                            box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
                            border-radius: 5px;
                            height: 85%;
                            width: 90%;
                        }
                    }
                }


            }



        }

        .footer-bottom-container {
            display: flex;
            align-items: center;
            justify-content: flex-start;
            width: 100%;
            padding-left: 10%;
            padding-right: 10%;
            height: 5%;
            background-color: $accent-color;

            .footer-bottom-name {
                color: white;

                font-family: $font-family;
                font-weight: 500;
                font-size: 0.7rem;

                margin: 0;
                margin-right: 1rem;
                padding: 0;

                width: fit-content;
            }

            .footer-bottom-social-container {
                display: flex;
                align-items: center;
                justify-content: space-between;
                height: 100%;
                width: 5%;

                .footer-bottom-icon {
                    color: white;
                    font-size: 1rem;
                }
            }
        }

        .footer-credit-icons-container {
            display: flex;
            width: 100%;
            padding-left: 10%;
            padding-right: 10%;
            justify-content: center;
            align-items: center;
            background-color: $accent-color;
            border: none;

            .footer-credit-icons {
                display: flex;
                justify-content: flex-start;
                align-items: center;

                width: 100%;
                height: 3vh;

                color: white;

                text-decoration: none;
                text-align: center;
                font-family: $font-family;
                font-size: 0.7rem;
                border: none;
                font-weight: 500;
            }
        }
    }
}

@media (min-width: 1400px) {
    .footer-container {
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: space-between;
        background-color: white;
        height: 100vh;
        width: 100%;
        padding-top: 10vh;
        border: 1px solid rgb(226, 226, 226);
        min-height: 800px;

        .footer-main-container {
            display: flex;
            flex-direction: row;
            align-items: center;
            justify-content: center;
            height: 100%;
            width: 100%;
            padding-bottom: 10vh;

            .footer-top-container {
                display: flex;
                flex-direction: column;
                align-items: center;
                justify-content: space-between;
                height: 100%;
                width: 80%;
                color: $dark-color;
                font-family: $font-family;

                .footer-top-text-container {
                    display: flex;
                    justify-content: flex-start;
                    height: 50%;
                    width: 100%;

                    .footer-links-container {
                        display: flex;
                        flex-direction: column;
                        text-align: start;
                        justify-content: space-between;
                        margin-right: 10vh;

                        .footer-contact-title {
                            margin: 0;
                            font-size: 2rem;
                            width: fit-content;

                            .footer-contact-title-color {
                                color: $accent-color;
                            }
                        }

                        .footer-link {
                            cursor: pointer;
                            display: flex;
                            align-items: center;
                            justify-content: center;

                            font-size: 1rem;
                            font-family: $font-family;
                            font-weight: 600;

                            color: $main-color;
                            background-color: $btn-color;

                            padding: 6px;
                            border-radius: 0.2rem;

                            box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
                            -webkit-box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
                            -moz-box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);

                            transition: 0.3s;
                            width: fit-content;
                        }
                    }

                    .footer-contact-container {
                        display: flex;
                        flex-direction: column;
                        text-align: start;
                        justify-content: space-between;
                        height: 70%;

                        .footer-contact-title {
                            margin: 0;
                            font-size: 2rem;
                            width: fit-content;
                        }

                        .footer-contact-single {
                            display: flex;
                            align-items: center;
                            font-family: Arial, Helvetica, sans-serif;
                            align-items: center;


                            .footer-icon {
                                margin-right: 0.8rem;
                                font-size: 1.5rem;
                                color: white;
                                background-color: $accent-color;
                                padding: 0.3rem;
                                height: 1rem;
                                width: 1rem;
                                border-radius: 50%;
                            }

                            .footer-contact-single-text {
                                font-family: $accent-font-family;
                                font-size: 1rem;
                                font-weight: 600;
                                color: #555;
                                margin: 0;
                            }
                        }
                    }
                }

                .footer-iframes-container {
                    display: flex;
                    width: 100%;
                    height: 50%;

                    .footer-iframe-container {
                        display: flex;
                        flex-direction: column;
                        text-align: start;
                        justify-content: space-between;
                        height: 100%;
                        width: 50%;

                        .footer-iframe-title {
                            text-align: start;
                            font-size: 1.5rem;
                            color: $dark-color;
                            font-family: $accent-font-family;
                            margin: 0;

                            .footer-iframe-title-color {
                                color: $accent-color;
                            }
                        }

                        .footer-iframe {
                            border: 1px solid rgb(226, 226, 226);
                            box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
                            border-radius: 5px;
                            height: 85%;
                            width: 90%;
                        }
                    }
                }


            }



        }

        .footer-bottom-container {
            display: flex;
            align-items: center;
            justify-content: flex-start;
            width: 100%;
            padding-left: 10%;
            padding-right: 10%;
            height: 5%;
            background-color: $accent-color;

            .footer-bottom-name {
                color: white;

                font-family: $font-family;
                font-weight: 500;
                font-size: 0.7rem;

                margin: 0;
                margin-right: 1rem;
                padding: 0;

                width: fit-content;
            }

            .footer-bottom-social-container {
                display: flex;
                align-items: center;
                justify-content: space-between;
                height: 100%;
                width: 5%;

                .footer-bottom-icon {
                    color: white;
                    font-size: 1rem;
                }
            }
        }

        .footer-credit-icons-container {
            display: flex;
            width: 100%;
            padding-left: 10%;
            padding-right: 10%;
            justify-content: center;
            align-items: center;
            background-color: $accent-color;
            border: none;

            .footer-credit-icons {
                display: flex;
                justify-content: flex-start;
                align-items: center;

                width: 100%;
                height: 3vh;

                color: white;

                text-decoration: none;
                text-align: center;
                font-family: $font-family;
                font-size: 0.7rem;
                border: none;
                font-weight: 500;
            }
        }
    }
}