@import '../../resources/variables/variables.scss';

@media (max-width: 768px) {
  .promotions-container {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    width: 100%;
    height: 100vh;
    background-color: white;
    min-height: 400px;

    .promotions-title-container {
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: center;
      width: 100%;

      .promotions-title-img {
        object-fit: cover;
        height: 3rem;
        rotate: 90deg;
      }

      .promotions-title {
        font-family: $font-family;
        font-size: 1.5rem;
        font-weight: 700;
        color: black;

        .promotions-title-color {
          color: $accent-color;
        }
      }
    }

    .promotions-slider-container {
      display: none;
    }

    .promotions-slider-mobile-container {
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: center;
      width: 95%;
      height: 80%;

      .swiper-wrapper {
        height: fit-content;
      }

      .swiper-container {
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        position: relative;
        width: 100%;
        height: 100%;

        .swiper-slide {
          display: flex;
          align-items: center;
          justify-content: center;
          align-self: center;
          position: relative;
          width: 50%;
          height: auto;
          margin: 0;
          padding: 0;
        }

        .swiper-slide img {
          object-fit: cover;
          width: 99%;
          height: 100%;
          border-radius: 5px;
          border: 2px solid rgb(231, 231, 231);
        }

      }

      .slider-tip {
        text-align: center;
        font-size: 1rem;
        font-family: $font-family;
        font-weight: 500;
        line-height: 150%;
        color: rgba(128, 128, 128, 0.882);
        margin-top: 1rem;
        margin-bottom: 1rem;
      }

      .swiper-interface {
        position: relative;
        display: flex;
        align-items: center;
        justify-content: center;
        width: 100%;
        height: 20%;
        margin-top: 0rem;

        .slider-controller {
          position: relative;
          display: flex;
          align-items: center;
          justify-content: center;
          height: 100%;
          width: 40%;

          .swiper-button-prev {
            width: 20%;
            height: 50%;
            color: $accent-color;
            border: none;
            background-color: transparent;
          }

          .swiper-button-next {
            width: 20%;
            height: 50%;
            color: $accent-color;
            border: none;
            background-color: transparent;
          }
        }

        .swipe-left-icon {
          object-fit: cover;
          width: 4rem;
          height: 4rem;
        }

        .swiper-pagination {
          display: none;
          align-items: center;
          justify-content: space-evenly;
          position: relative;
          bottom: 0;
          color: $accent-color;
          height: 100%;
          width: 50%;
        }
      }

    }
  }
}

@media (min-width: 769px) {
  .promotions-container {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    width: 100%;
    height: 100vh;

    min-height: 400px;

    background-image: url('../../resources/images/bg-1.webp');
    background-size: 100% auto;
    /* Adjusted background-size property */
    background-repeat: no-repeat;
    background-position: right bottom;
    /* Adjusted background-position property */
    box-shadow: none;


    .promotions-title-container {
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: center;
      width: 100%;

      .promotions-title-img {
        object-fit: cover;
        width: 2rem;
        rotate: 90deg;
      }

      .promotions-title {
        font-family: $font-family;
        font-size: 2.5rem;
        font-weight: 700;

        margin-bottom: 2rem;

        color: $dark-color;

        .promotions-title-color {
          color: $accent-color;
        }
      }
    }

    .promotions-slider-container {
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: center;
      width: 70%;
      height: 70%;

      .swiper-wrapper {
        height: fit-content;
      }

      .swiper-container {
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        position: relative;
        width: 100%;
        height: 100%;

        .swiper-slide {
          display: flex;
          align-items: center;
          justify-content: center;
          align-self: center;
          position: relative;
          width: 50%;
          height: auto;
          margin: 0;
          padding: 0;
        }

        .swiper-slide img {
          object-fit: cover;
          width: 99%;
          height: 100%;
          border-radius: 5px;
          border: none;
        }

      }

      .slider-tip {
        text-align: center;
        font-size: 1rem;
        font-family: $font-family;
        font-weight: 500;
        line-height: 150%;
        color: rgba(128, 128, 128, 0.882);
        margin-top: 1rem;
        margin-bottom: 1rem;
      }

      .swiper-interface {
        position: relative;
        display: flex;
        align-items: center;
        justify-content: center;
        width: 100%;
        height: 20%;
        margin-top: 0rem;

        .slider-controller {
          position: relative;
          display: flex;
          align-items: center;
          justify-content: center;
          height: 100%;
          width: 40%;

          .swiper-button-prev {
            width: 20%;
            height: 50%;
            color: $accent-color;
            border: none;
            background-color: transparent;
          }

          .swiper-button-next {
            width: 20%;
            height: 50%;
            color: $accent-color;
            border: none;
            background-color: transparent;
          }
        }

        .swipe-left-icon {
          object-fit: cover;
          width: 4rem;
          height: 4rem;
        }

        .swiper-pagination {
          display: none;
          align-items: center;
          justify-content: space-evenly;
          position: relative;
          bottom: 0;
          color: $accent-color;
          height: 100%;
          width: 50%;
        }
      }
    }

    .promotions-slider-mobile-container {
      display: none;
      flex-direction: column;
      align-items: center;
      justify-content: center;
      width: 95%;
      height: 80%;

      .swiper-wrapper {
        height: fit-content;
      }

      .swiper-container {
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        position: relative;
        width: 100%;
        height: 100%;

        .swiper-slide {
          display: flex;
          align-items: center;
          justify-content: center;
          align-self: center;
          position: relative;
          width: 50%;
          height: auto;
          margin: 0;
          padding: 0;
        }

        .swiper-slide img {
          object-fit: cover;
          width: 99%;
          height: 100%;
          border-radius: 5px;
          border: 2px solid rgb(231, 231, 231);
        }

      }

      .slider-tip {
        text-align: center;
        font-size: 1rem;
        font-family: $font-family;
        font-weight: 500;
        line-height: 150%;
        color: rgba(128, 128, 128, 0.882);
        margin-top: 1rem;
        margin-bottom: 1rem;
      }

      .swiper-interface {
        position: relative;
        display: flex;
        align-items: center;
        justify-content: center;
        width: 100%;
        height: 20%;
        margin-top: 0rem;

        .slider-controller {
          position: relative;
          display: flex;
          align-items: center;
          justify-content: center;
          height: 100%;
          width: 40%;

          .swiper-button-prev {
            width: 20%;
            height: 50%;
            color: $accent-color;
            border: none;
            background-color: transparent;
          }

          .swiper-button-next {
            width: 20%;
            height: 50%;
            color: $accent-color;
            border: none;
            background-color: transparent;
          }
        }

        .swipe-left-icon {
          object-fit: cover;
          width: 4rem;
          height: 4rem;
        }

        .swiper-pagination {
          display: none;
          align-items: center;
          justify-content: space-evenly;
          position: relative;
          bottom: 0;
          color: $accent-color;
          height: 100%;
          width: 50%;
        }
      }

    }
  }
}

@media (min-width: 1200px) {
  .promotions-container {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    width: 100%;
    height: 100vh;
    background-image: url('../../resources/images/bg-1.webp');
    /* Adjusted background-size property */
    background-repeat: no-repeat;
    background-position: right;
    background-size: 75%;

    min-height: 400px;
    background-color: white;
    box-shadow: none;

    .promotions-title-container {
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: center;
      width: 100%;

      .promotions-title-img {
        object-fit: cover;
        width: 3rem;
        rotate: 90deg;
        user-select: none;
        -webkit-user-drag: none;
      }

      .promotions-title {
        font-family: $font-family;
        font-size: 4rem;
        font-weight: 700;

        margin-bottom: 4rem;

        color: $dark-color;

        .promotions-title-color {
          color: $accent-color;
        }
      }
    }

    .promotions-slider-container {
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: center;
      width: 50%;
      height: 60%;

      .swiper-wrapper {
        height: fit-content;
      }

      .swiper-container {
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        position: relative;
        width: 100%;
        height: 100%;

        .swiper-slide {
          display: flex;
          align-items: center;
          justify-content: center;
          align-self: center;
          position: relative;
          width: 50%;
          height: auto;
          margin: 0;
          padding: 0;
        }

        .swiper-slide img {
          object-fit: cover;
          width: 99%;
          height: 100%;
          border-radius: 5px;
          border: 2px solid rgb(231, 231, 231);
        }

      }

      .slider-tip {
        text-align: center;
        font-size: 1rem;
        font-family: $font-family;
        font-weight: 500;
        line-height: 150%;
        color: rgba(128, 128, 128, 0.882);
        margin-top: 1rem;
        margin-bottom: 1rem;
      }

      .swiper-interface {
        position: relative;
        display: flex;
        align-items: center;
        justify-content: center;
        width: 100%;
        height: 20%;
        margin-top: 0rem;

        .slider-controller {
          position: relative;
          display: flex;
          align-items: center;
          justify-content: center;
          height: 100%;
          width: 40%;

          .swiper-button-prev {
            width: 20%;
            height: 50%;
            color: $accent-color;
            border: none;
            background-color: transparent;
          }

          .swiper-button-next {
            width: 20%;
            height: 50%;
            color: $accent-color;
            border: none;
            background-color: transparent;
          }
        }

        .swipe-left-icon {
          object-fit: cover;
          width: 4rem;
          height: 4rem;
        }

        .swiper-pagination {
          display: none;
          align-items: center;
          justify-content: space-evenly;
          position: relative;
          bottom: 0;
          color: $accent-color;
          height: 100%;
          width: 50%;
        }
      }
    }

    .promotions-slider-mobile-container {
      display: none;
      flex-direction: column;
      align-items: center;
      justify-content: center;
      width: 95%;
      height: 80%;

      .swiper-wrapper {
        height: fit-content;
      }

      .swiper-container {
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        position: relative;
        width: 100%;
        height: 100%;

        .swiper-slide {
          display: flex;
          align-items: center;
          justify-content: center;
          align-self: center;
          position: relative;
          width: 50%;
          height: auto;
          margin: 0;
          padding: 0;
        }

        .swiper-slide img {
          object-fit: cover;
          width: 99%;
          height: 100%;
          border-radius: 5px;
          border: 2px solid rgb(231, 231, 231);
        }

      }

      .slider-tip {
        text-align: center;
        font-size: 1rem;
        font-family: $font-family;
        font-weight: 500;
        line-height: 150%;
        color: rgba(128, 128, 128, 0.882);
        margin-top: 1rem;
        margin-bottom: 1rem;
      }

      .swiper-interface {
        position: relative;
        display: flex;
        align-items: center;
        justify-content: center;
        width: 100%;
        height: 20%;
        margin-top: 0rem;

        .slider-controller {
          position: relative;
          display: flex;
          align-items: center;
          justify-content: center;
          height: 100%;
          width: 40%;

          .swiper-button-prev {
            width: 20%;
            height: 50%;
            color: $accent-color;
            border: none;
            background-color: transparent;
          }

          .swiper-button-next {
            width: 20%;
            height: 50%;
            color: $accent-color;
            border: none;
            background-color: transparent;
          }
        }

        .swipe-left-icon {
          object-fit: cover;
          width: 4rem;
          height: 4rem;
        }

        .swiper-pagination {
          display: none;
          align-items: center;
          justify-content: space-evenly;
          position: relative;
          bottom: 0;
          color: $accent-color;
          height: 100%;
          width: 50%;
        }
      }

    }
  }
}