@import '../../resources/variables/variables.scss';

@media (max-width: 768px) {
    .contact-container {
        display: flex;
        flex-direction: column;
        align-items: center;
        background-color: rgb(244, 244, 244);
        background-image: url('../../resources/images/didyoubg.webp');
        background-position: center;
        background-size:auto;  
        height: auto;
        min-height: 400px;
        padding-top: 6rem;
        padding-bottom: 6rem;
    
        box-shadow: 0px 0px 11px -4px rgba(0, 0, 0, 0.75);
        -webkit-box-shadow: 0px 0px 11px -4px rgba(0, 0, 0, 0.75);
        -moz-box-shadow: 0px 0px 11px -4px rgba(0, 0, 0, 0.75);
    
        .contact-title-container {
            display: flex;
            flex-direction: column;
            align-items: center;
            width: 100%;
            margin-bottom: 3rem;
    
            .contact-title-img {
                object-fit: cover;
                width: 2rem;
                transform: rotate(90deg);
                user-select: none;
        -webkit-user-drag: none;
            }
    
            .contact-title {
                margin: -1rem;
                color: $accent-color;
                font-family: $font-family;
                font-size: 2rem;
            }
    
            .contact-title-color {
                color: black;
            }
        }
    
        .contact-icons-container {
            display: flex;
            justify-content: center;
            width: 90%;
    
            .contact-icon-container {
                display: flex;
                justify-content: center;
                align-items: center;
    
                width: 8rem;
                height: 7.5rem;
                margin: 0.5rem;
    
                background-color: $accent-color;
    
                box-shadow: 0px 0px 11px -4px rgba(0, 0, 0, 0.75);
                -webkit-box-shadow: 0px 0px 11px -4px rgba(0, 0, 0, 0.75);
                -moz-box-shadow: 0px 0px 11px -4px rgba(0, 0, 0, 0.75);
    
                .footer-icon {
                    color: white;
                    font-size: 5rem;
                }
            }
    
        }
    
        .contact-iframe {
    
            box-shadow: 0px 0px 11px -4px rgba(0, 0, 0, 0.75);
            -webkit-box-shadow: 0px 0px 11px -4px rgba(0, 0, 0, 0.75);
            -moz-box-shadow: 0px 0px 11px -4px rgba(0, 0, 0, 0.75);
    
            margin-top: 6rem;
            border: none;
        }
    }
}

@media (min-width: 769px) {
    .contact-container {
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        background-color: white;
        background-image: url('../../resources/images/bg-3.webp');
        background-size: 70% auto;
        /* Adjusted background-size property */
        background-repeat: no-repeat;
        background-position: left top;
        height: auto;
        min-height: 400px;
    
        padding-top: 6rem;
        padding-bottom: 6rem;
    
        box-shadow: none;
    
        .contact-title-container {
            display: flex;
            flex-direction: column;
            align-items: center;
            width: 100%;
            margin-bottom: 3rem;
    
            .contact-title-img {
                object-fit: cover;
                height: 5rem;
                width: 3rem;
                transform: rotate(90deg);
                user-select: none;
        -webkit-user-drag: none;
            }
    
            .contact-title {
                margin: -1rem;
                color: $accent-color;
                font-family: $font-family;
                font-size: 2.5rem;
            }
    
            .contact-title-color {
                color: black;
            }
        }
    
        .contact-icons-container {
            display: flex;
            justify-content: center;
            width: 90%;
    
            .contact-icon-container {
                cursor: pointer;
                display: flex;
                justify-content: center;
                align-items: center;
    
                width: 10rem;
                height: 8rem;
                margin: 0.5rem;
                background-color: $accent-color;
    
                box-shadow: 0px 0px 11px -4px rgba(0, 0, 0, 0.75);
                -webkit-box-shadow: 0px 0px 11px -4px rgba(0, 0, 0, 0.75);
                -moz-box-shadow: 0px 0px 11px -4px rgba(0, 0, 0, 0.75);

                transition: 0.3s;

                &:hover{
                    background-color: white;
                }
                
    
                .footer-icon {
                    color: white;
                    font-size: 5rem;
                }

                &:hover .footer-icon{
                    color: $accent-color;
                    font-size: 5rem;               
                }
            }
    
        }
    
        .contact-iframe {
    
            box-shadow: 0px 0px 11px -4px rgba(0, 0, 0, 0.75);
            -webkit-box-shadow: 0px 0px 11px -4px rgba(0, 0, 0, 0.75);
            -moz-box-shadow: 0px 0px 11px -4px rgba(0, 0, 0, 0.75);
    
            margin-top: 6rem;
            border: none;
        }
    }
}
