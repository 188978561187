@import '../../resources/variables/variables.scss';


@media (max-width: 768px) {
  .nav-container {
    position: fixed;
    top: 0;
    display: flex;
    align-items: center;
    justify-content: center;
    height: 10vh;
    width: 100%;
    opacity: 100%;
    color: white;
    background-color: transparent; // Start with a transparent background
    z-index: 99;
    transition: background-color 0.3s ease; // Add a smooth transition for the background color
  
    .nav-links-container {
      display: flex;
      align-items: center;
      justify-content: space-between;
      width: 70%;
      height: 100%;
  
      .desktop-menu-title-container{
        display: flex;
        align-items: center;
        height: 100%;
  
        .desktop-menu-img{
          object-fit: cover;
          height: 70%;
          margin-right: 0.5rem;
        }
  
        .desktop-menu-title{
          font-family: $font-family;
          font-weight: 400;
        }
      }
  
      .desktop-nav-links-container{
        display: flex;
        align-items: center;
        justify-content: space-between;
        height: 100%;
        width: 50%;
  
        .nav-single-link {
          cursor: pointer;
          display: inline-block;
          position: relative;
          font-size: 0.8rem;
          font-family: $font-family;
          font-weight: 400;
          color: $main-color;
          transition: 0.3s;
    
          &::after {
            content: '';
            position: absolute;
            width: 100%;
            transform: scaleX(0);
            height: 2px;
            bottom: 0;
            left: 0;
            background-color: $main-color;
            transform-origin: bottom right;
            transition: transform 0.25s ease-out;
          }
    
          &:hover::after {
            transform: scaleX(1);
            transform-origin: bottom left;
          }
    
          &:focus {
            cursor: pointer;
            text-decoration: none;
            color: white;
          }
        }
      }
      
    }
  
    @media screen and (max-width: 768px) {
      /* Adjust styles for mobile screens */
      justify-content: space-between;
      padding: 0px;
      background-color: transparent;
  
      .nav-links-container {
        display: none;
      }
  
      .mobile-menu {
        display: flex;
        align-items: center;
        height: 8vh;
        width: 100%;
      }
  
      .mobile-menu-button {
        display: block;
        color: white;
        background-color: $btn-color;
        border: none;
        border-radius: 5px;
        font-size: 1.5rem;
        cursor: pointer;
        margin-left: 15px;
  
        &:hover {
          background-color: $btn-color-hover;
        }
  
        &:active {
          background-color: $btn-color-active;
        }
      }
  
      .mobile-menu-title-container {
        display: flex;
        align-items: center;
        height: 100%;
        margin-left: 5%;
  
        .mobile-menu-title {
          font-size: 1.5rem;
          font-family: $font-family;
          font-weight: 400;
        }
  
        .mobile-menu-img {
          object-fit: cover;
          height: 80%;
          margin-right: 0.5rem;
        }
      }
  
      .mobile-dropdown {
        display: flex;
        flex-direction: column;
        align-items: flex-start;
        justify-content: space-between;
        position: absolute;
        top: 100%;
        left: 0;
        background-color: rgba(0, 0, 0, 0.904);
        width: 100%;
        padding: 20px;
        z-index: 100;
        transition: 0.8s;
        max-height: 0; /* Start with a height of 0, so it's hidden initially */
        overflow: hidden; /* Hide any content that overflows the container */
        animation: slideDown 0.3s ease forwards; /* Apply the animation */
      }
      
      .mobile-dropdown-link {
        margin-bottom: 20px;
        font-size: 1rem;
        font-family: $font-family;
      }
      
     /* Define the keyframes for sliding down */
@keyframes slideDown {
  0% {
    max-height: 0;
  }
  100% {
    max-height: 500px; /* Adjust the final height to your preference */
  }
}

/* Define the keyframes for sliding up */
@keyframes slideUp {
  0% {
    max-height: 500px; /* Set the final height from slideDown animation */
  }
  100% {
    max-height: 0;
  }
}

/* Add a class to trigger the dropdown animation */
.opened {
  animation: slideDown 0.3s ease forwards;
}

/* Add a class to trigger the retract animation */
.closed {
  animation: slideUp 0.3s ease forwards;
}
      
    }
  
    // Add the colored-nav class for the colored navbar
    &.colored-nav {
    background-color: white; // Set the color you want when the navbar is colored
      opacity: 100%; // You may adjust the opacity if needed
      box-shadow: 0px 4px 25px 0px rgba(0, 0, 0, 0.05);
      -webkit-box-shadow: 0px 4px 25px 0px rgba(0, 0, 0, 0.05);
      -moz-box-shadow: 0px 4px 25px 0px rgba(0, 0, 0, 0.05);

      .mobile-menu-title-container {
        display: flex;
        align-items: center;
        height: 100%;
        margin-left: 5%;
  
        .mobile-menu-title {
          font-size: 1.5rem;
          font-family: $font-family;
          font-weight: 600;
          color: $dark-color;
        }
  
        .mobile-menu-img {
          object-fit: cover;
          height: 80%;
          margin-right: 0.5rem;
        }
      }
    }
  }
}

@media (min-width: 769px) {
  .nav-container {
    position: fixed;
    top: 0;
    display: flex;
    align-items: center;
    justify-content: center;
    height: 10vh;
    width: 100%;
    opacity: 100%;
    color: white;
    background-color: transparent; // Start with a transparent background
    z-index: 99;
    transition: background-color 0.3s ease; // Add a smooth transition for the background color
  
    .nav-links-container {
      display: flex;
      align-items: center;
      justify-content: space-between;
      width: 85%;
      height: 100%;
  
      .desktop-menu-title-container{
        display: flex;
        align-items: center;
        height: 100%;
  
        .desktop-menu-img{
          object-fit: cover;
          height: 70%;
          margin-right: 0.5rem;
        }
  
        .desktop-menu-title{
          font-family: $font-family;
          font-weight: 400;
          font-size: 1.2rem;
        }
      }
  
      .desktop-nav-links-container{
        display: flex;
        align-items: center;
        justify-content: space-between;
        height: 100%;
        width: 65%;
  
        .nav-single-link {
          cursor: pointer;
          display: inline-block;
          position: relative;
          font-size: 0.7rem;
          font-family: $font-family;
          font-weight: 400;
          color: $main-color;
          transition: 0.3s;
    
          &::after {
            content: '';
            position: absolute;
            width: 100%;
            transform: scaleX(0);
            height: 2px;
            bottom: 0;
            left: 0;
            background-color: $main-color;
            transform-origin: bottom right;
            transition: transform 0.25s ease-out;
          }
    
          &:hover::after {
            transform: scaleX(1);
            transform-origin: bottom left;
          }
    
          &:focus {
            cursor: pointer;
            text-decoration: none;
            color: white;
          }
        }
      }
      
    }
  
    @media screen and (max-width: 768px) {
      /* Adjust styles for mobile screens */
      justify-content: space-between;
      padding: 0px;
      background-color: transparent;
  
      .nav-links-container {
        display: none;
      }
  
      .mobile-menu {
        display: flex;
        align-items: center;
        height: 8vh;
        width: 100%;
      }
  
      .mobile-menu-button {
        display: block;
        color: white;
        background-color: $btn-color;
        border: none;
        border-radius: 5px;
        font-size: 1.5rem;
        cursor: pointer;
        margin-left: 15px;
  
        &:hover {
          background-color: $btn-color-hover;
        }
  
        &:active {
          background-color: $btn-color-active;
        }
      }
  
      .mobile-menu-title-container {
        display: flex;
        align-items: center;
        height: 100%;
        margin-left: 5%;
  
        .mobile-menu-title {
          font-size: 1.5rem;
          font-family: $font-family;
          font-weight: 400;
        }
  
        .mobile-menu-img {
          object-fit: cover;
          height: 80%;
          margin-right: 0.5rem;
        }
      }
  
      .mobile-dropdown {
        display: flex;
        flex-direction: column;
        align-items: flex-start;
        justify-content: space-between;
        position: absolute;
        top: 100%;
        left: 0;
        background-color: $accent-color;
        width: 100%;
        padding: 20px;
        z-index: 100;
  
        .mobile-dropdown-link {
          margin-bottom: 20px;
          font-size: 1rem;
          font-family: $font-family;
        }
      }
    }
  
    // Add the colored-nav class for the colored navbar
    &.colored-nav {
      background-color: white; // Set the color you want when the navbar is colored
      opacity: 100%; // You may adjust the opacity if needed
      box-shadow: 0px 4px 25px 0px rgba(0, 0, 0, 0.05);
      -webkit-box-shadow: 0px 4px 25px 0px rgba(0, 0, 0, 0.05);
      -moz-box-shadow: 0px 4px 25px 0px rgba(0, 0, 0, 0.05);
      box-shadow: 0px 4px 10px rgba(52, 152, 219, 0.3);

      .desktop-menu-title-container{
        display: flex;
        align-items: center;
        height: 100%;
  
        .desktop-menu-img{
          object-fit: cover;
          height: 70%;
          margin-right: 0.5rem;
        }
  
        .desktop-menu-title{
          font-family: $font-family;
          font-weight: 500;
          color: $dark-color;
          text-shadow: none;
        }
      }

      .desktop-nav-links-container{
        display: flex;
        align-items: center;
        justify-content: space-between;
        height: 100%;
        width: 65%;
  
        .nav-single-link {
          cursor: pointer;
          display: inline-block;
          position: relative;
          font-size: 0.7rem;
          font-family: $font-family;
          font-weight: 500;
          color: $dark-color;
          transition: 0.3s;
          text-shadow: none;

          &:hover{
            color: $accent-color;
          }

          &::after {
            content: '';
            position: absolute;
            width: 100%;
            transform: scaleX(0);
            height: 2px;
            bottom: 0;
            left: 0;
            background-color: $accent-color;
            transform-origin: bottom right;
            transition: transform 0.25s ease-out;
          }
    
          &:hover::after {
            transform: scaleX(1);
            transform-origin: bottom left;
          }
    
          &:focus {
            cursor: pointer;
            text-decoration: none;
            color: white;
          }
        }
      }
    }
  }
}

@media (min-width: 1400px) {
  .nav-container {
    position: fixed;
    top: 0;
    display: flex;
    align-items: center;
    justify-content: center;
    height: 10vh;
    width: 100%;
    opacity: 100%;
    color: white;
    background-color: transparent; // Start with a transparent background
    z-index: 99;
    transition: background-color 0.3s ease; // Add a smooth transition for the background color
  
    .nav-links-container {
      display: flex;
      align-items: center;
      justify-content: space-between;
      width: 70%;
      height: 100%;
  
      .desktop-menu-title-container{
        display: flex;
        align-items: center;
        height: 100%;
  
        .desktop-menu-img{
          object-fit: cover;
          height: 70%;
          margin-right: 0.5rem;
        }
  
        .desktop-menu-title{
          font-family: $font-family;
          font-weight: 500;
          color: white;
          text-shadow: 0px 0px 6px rgba(0,0,0,0.6);
        }
      }
  
      .desktop-nav-links-container{
        display: flex;
        align-items: center;
        justify-content: space-between;
        height: 100%;
        width: 60%;
  
        .nav-single-link {
          cursor: pointer;
          display: inline-block;
          position: relative;
          font-size: 0.9rem;
          font-family: $font-family;
          font-weight: 500;
          color: white;
          transition: 0.3s;
          text-shadow: 0px 0px 6px rgba(0,0,0,0.6);


          &::after {
            content: '';
            position: absolute;
            width: 100%;
            transform: scaleX(0);
            height: 2px;
            bottom: 0;
            left: 0;
            background-color: $main-color;
            transform-origin: bottom right;
            transition: transform 0.25s ease-out;
          }
    
          &:hover::after {
            transform: scaleX(1);
            transform-origin: bottom left;
          }
    
          &:focus {
            cursor: pointer;
            text-decoration: none;
            color: white;
          }
        }
      }
      
    }
  
    @media screen and (max-width: 768px) {
      /* Adjust styles for mobile screens */
      justify-content: space-between;
      padding: 0px;
      background-color: transparent;
  
      .nav-links-container {
        display: none;
      }
  
      .mobile-menu {
        display: flex;
        align-items: center;
        height: 8vh;
        width: 100%;
      }
  
      .mobile-menu-button {
        display: block;
        color: white;
        background-color: $btn-color;
        border: none;
        border-radius: 5px;
        font-size: 1.5rem;
        cursor: pointer;
        margin-left: 15px;
  
        &:hover {
          background-color: $btn-color-hover;
        }
  
        &:active {
          background-color: $btn-color-active;
        }
      }
  
      .mobile-menu-title-container {
        display: flex;
        align-items: center;
        height: 100%;
        margin-left: 5%;
  
        .mobile-menu-title {
          font-size: 1.5rem;
          font-family: $font-family;
          font-weight: 400;
        }
  
        .mobile-menu-img {
          object-fit: cover;
          height: 80%;
          margin-right: 0.5rem;
        }
      }
  
      .mobile-dropdown {
        display: flex;
        flex-direction: column;
        align-items: flex-start;
        justify-content: space-between;
        position: absolute;
        top: 100%;
        left: 0;
        background-color: $accent-color;
        width: 100%;
        padding: 20px;
        z-index: 100;
  
        .mobile-dropdown-link {
          margin-bottom: 20px;
          font-size: 1rem;
          font-family: $font-family;
        }
      }
    }
  
    // Add the colored-nav class for the colored navbar
    &.colored-nav {
      background-color: white; // Set the color you want when the navbar is colored
      opacity: 100%; // You may adjust the opacity if needed
      box-shadow: 0px 4px 25px 0px rgba(0, 0, 0, 0.05);
      -webkit-box-shadow: 0px 4px 25px 0px rgba(0, 0, 0, 0.05);
      -moz-box-shadow: 0px 4px 25px 0px rgba(0, 0, 0, 0.05);
      box-shadow: 0px 4px 10px rgba(52, 152, 219, 0.3);

      .desktop-menu-title-container{
        display: flex;
        align-items: center;
        height: 100%;
  
        .desktop-menu-img{
          object-fit: cover;
          height: 70%;
          margin-right: 0.5rem;
        }
  
        .desktop-menu-title{
          font-family: $font-family;
          font-weight: 500;
          color: $dark-color;
          text-shadow: none;
        }
      }

      .desktop-nav-links-container{
        display: flex;
        align-items: center;
        justify-content: space-between;
        height: 100%;
        width: 60%;
  
        .nav-single-link {
          cursor: pointer;
          display: inline-block;
          position: relative;
          font-size: 0.9rem;
          font-family: $font-family;
          font-weight: 500;
          color: $dark-color;
          transition: 0.3s;
          text-shadow: none;

          &:hover{
            color: $accent-color;
          }

          &::after {
            content: '';
            position: absolute;
            width: 100%;
            transform: scaleX(0);
            height: 2px;
            bottom: 0;
            left: 0;
            background-color: $accent-color;
            transform-origin: bottom right;
            transition: transform 0.25s ease-out;
          }
    
          &:hover::after {
            transform: scaleX(1);
            transform-origin: bottom left;
          }
    
          &:focus {
            cursor: pointer;
            text-decoration: none;
            color: white;
          }
        }
      }
    }
  }
}

@media (min-width: 1600px) {
  .nav-container {
    position: fixed;
    top: 0;
    display: flex;
    align-items: center;
    justify-content: center;
    height: 8vh;
    width: 100%;
    opacity: 100%;
    color: white;
    background-color: transparent; // Start with a transparent background
    z-index: 99;
    transition: background-color 0.3s ease; // Add a smooth transition for the background color

    .nav-links-container {
      display: flex;
      align-items: center;
      justify-content: space-between;
      width: 70%;
      height: 100%;
  
      .desktop-menu-title-container{
        display: flex;
        align-items: center;
        height: 100%;
  
        .desktop-menu-img{
          object-fit: cover;
          height: 70%;
          margin-right: 0.5rem;
        }
  
        .desktop-menu-title{
          cursor: pointer;
          font-family: $font-family;
          font-weight: 500;
          color: white;
          text-shadow: 0px 0px 6px rgba(0,0,0,0.6);
        }
      }
  
      .desktop-nav-links-container{
        display: flex;
        align-items: center;
        justify-content: space-between;
        height: 100%;
        width: 50%;
  
        .nav-single-link {
          cursor: pointer;
          display: inline-block;
          position: relative;
          font-size: 0.9rem;
          font-family: $font-family;
          font-weight: 500;
          color: white;
          transition: 0.3s;
          text-shadow: 0px 0px 6px rgba(0,0,0,0.6);


          &::after {
            content: '';
            position: absolute;
            width: 100%;
            transform: scaleX(0);
            height: 2px;
            bottom: 0;
            left: 0;
            background-color: $main-color;
            transform-origin: bottom right;
            transition: transform 0.25s ease-out;
          }
    
          &:hover::after {
            transform: scaleX(1);
            transform-origin: bottom left;
          }
    
          &:focus {
            cursor: pointer;
            text-decoration: none;
            color: white;
          }
        }
      }
      
    }
  
    @media screen and (max-width: 768px) {
      /* Adjust styles for mobile screens */
      justify-content: space-between;
      padding: 0px;
      background-color: transparent;
  
      .nav-links-container {
        display: none;
      }
  
      .mobile-menu {
        display: flex;
        align-items: center;
        height: 8vh;
        width: 100%;
      }
  
      .mobile-menu-button {
        display: block;
        color: white;
        background-color: $btn-color;
        border: none;
        border-radius: 5px;
        font-size: 1.5rem;
        cursor: pointer;
        margin-left: 15px;
  
        &:hover {
          background-color: $btn-color-hover;
        }
  
        &:active {
          background-color: $btn-color-active;
        }
      }
  
      .mobile-menu-title-container {
        display: flex;
        align-items: center;
        height: 100%;
        margin-left: 5%;
  
        .mobile-menu-title {
          font-size: 1.5rem;
          font-family: $font-family;
          font-weight: 400;
        }
  
        .mobile-menu-img {
          object-fit: cover;
          height: 80%;
          margin-right: 0.5rem;
        }
      }
  
      .mobile-dropdown {
        display: flex;
        flex-direction: column;
        align-items: flex-start;
        justify-content: space-between;
        position: absolute;
        top: 100%;
        left: 0;
        background-color: $accent-color;
        width: 100%;
        padding: 20px;
        z-index: 100;
  
        .mobile-dropdown-link {
          margin-bottom: 20px;
          font-size: 1rem;
          font-family: $font-family;
        }
      }
    }
  
    // Add the colored-nav class for the colored navbar
    &.colored-nav {
      background-color: white; // Set the color you want when the navbar is colored
      opacity: 100%; // You may adjust the opacity if needed
      box-shadow: 0px 4px 25px 0px rgba(0, 0, 0, 0.05);
      -webkit-box-shadow: 0px 4px 25px 0px rgba(0, 0, 0, 0.05);
      -moz-box-shadow: 0px 4px 25px 0px rgba(0, 0, 0, 0.05);
      box-shadow: 0px 4px 10px rgba(52, 152, 219, 0.3);

      .desktop-menu-title-container{
        display: flex;
        align-items: center;
        height: 100%;
  
        .desktop-menu-img{
          object-fit: cover;
          height: 70%;
          margin-right: 0.5rem;
        }
  
        .desktop-menu-title{
          font-family: $font-family;
          font-weight: 500;
          color: $dark-color;
          text-shadow: none;
        }
      }

      .desktop-nav-links-container{
        display: flex;
        align-items: center;
        justify-content: space-between;
        height: 100%;
        width: 50%;
  
        .nav-single-link {
          cursor: pointer;
          display: inline-block;
          position: relative;
          font-size: 0.9rem;
          font-family: $font-family;
          font-weight: 500;
          color: $dark-color;
          transition: 0.3s;
          text-shadow: none;

          &:hover{
            color: $accent-color;
          }

          &::after {
            content: '';
            position: absolute;
            width: 100%;
            transform: scaleX(0);
            height: 2px;
            bottom: 0;
            left: 0;
            background-color: $accent-color;
            transform-origin: bottom right;
            transition: transform 0.25s ease-out;
          }
    
          &:hover::after {
            transform: scaleX(1);
            transform-origin: bottom left;
          }
    
          &:focus {
            cursor: pointer;
            text-decoration: none;
            color: white;
          }
        }
      }
    }
  }
}