@import '../../resources/variables/variables.scss';


@media (max-width: 768px) {
  .writeus-container {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;

    height: 50vh;
    width: 100%;

    padding-left: 0%;
    padding-right: 0%;

    box-shadow: 0px 0px 11px -4px rgba(0, 0, 0, 0.75);
    -webkit-box-shadow: 0px 0px 11px -4px rgba(0, 0, 0, 0.75);
    -moz-box-shadow: 0px 0px 11px -4px rgba(0, 0, 0, 0.75);

    z-index: 1;
    background-color: $accent-color-second;
    min-height: 400px; /* Set a fixed minimum height */

    .writeus-text-container {
      display: flex;
      flex-direction: column;
      justify-content: center;

      height: fit-content;
      width: 90%;
      text-align: center;
      margin-bottom: 10%;

      .writeus-title {
        color: $main-color;

        font-size: 2.5rem;
        font-weight: 700;
        font-family: $font-family;

        margin: 0;
        text-shadow: 1px 1px 2px rgba(0, 0, 0, 0.3);
        -webkit-text-shadow: 1px 1px 2px rgba(0, 0, 0, 0.3);

      }

      .writeus-text {
        color: $main-color;

        font-size: 0.8rem;
        font-weight: 500;
        font-family: $accent-font-family;

        margin: 0;
        margin-top: 1rem;
        text-shadow: 1px 1px 2px rgba(0, 0, 0, 0.3);
        -webkit-text-shadow: 1px 1px 2px rgba(0, 0, 0, 0.3);

      }
    }

    .writeus-button-container {
      display: flex;
      align-items: center;
      justify-content: center;
      height: 25%;
      width: 100%;
      margin: 0;

      .writus-button {
        text-decoration: none;
        cursor: pointer;
        display: flex;
        align-items: center;
        justify-content: center;

        border: 3px solid $main-color;
        border-radius: 5px;
        background-color: transparent;

        width: 90%;
        height: 5rem;

        margin: 0;

        color: $main-color;
        font-size: 1.5rem;
        text-shadow: 1px 1px 2px rgba(0, 0, 0, 0.3);
        -webkit-text-shadow: 1px 1px 2px rgba(0, 0, 0, 0.3);
        transition: 0.3s;
        box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);

        &:hover {
          color: $dark-color;
          background-color: $main-color;
        }

        &:active {
          color: $dark-color;
          border: 3px solid rgb(243, 243, 243);
          background-color: rgb(243, 243, 243);
          /* Fixed the typo here */
        }

        .writeus-icon {
          font-size: 2rem;
          margin-right: 0.5rem;
        }
      }

    }
  }
}

@media (min-width: 769px) {
  .writeus-container {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;

    min-height: 20rem; /* Set a fixed minimum height */
    height: auto;
    width: 100%;

    padding-left: 12.5%;
    padding-right: 12.5%;

    box-shadow: 0px 0px 11px -4px rgba(0, 0, 0, 0.75);
    -webkit-box-shadow: 0px 0px 11px -4px rgba(0, 0, 0, 0.75);
    -moz-box-shadow: 0px 0px 11px -4px rgba(0, 0, 0, 0.75);

    z-index: 1;
    background-color: $accent-color-second;

    .writeus-text-container {
      display: flex;
      flex-direction: column;
      justify-content: center;

      height: 80%;
      width: 60%;
      text-align: start;

      .writeus-title {
        color: $main-color;

        font-size: 2rem;
        font-weight: 700;
        font-family: $font-family;

        margin: 0;
        text-shadow: 1px 1px 2px rgba(0, 0, 0, 0.3);
        -webkit-text-shadow: 1px 1px 2px rgba(0, 0, 0, 0.3);

      }

      .writeus-text {
        color: $main-color;

        font-size: 0.8rem;
        font-weight: 500;
        font-family: $accent-font-family;

        margin: 0;
        margin-top: 1rem;
        text-shadow: 1px 1px 2px rgba(0, 0, 0, 0.3);
        -webkit-text-shadow: 1px 1px 2px rgba(0, 0, 0, 0.3);

      }
    }

    .writeus-button-container {
      display: flex;
      align-items: center;
      justify-content: center;
      height: 80%;
      width: 35%;
      margin: 0;

      .writus-button {
        cursor: pointer;
        display: flex;
        align-items: center;
        justify-content: center;

        border: 3px solid $main-color;
        border-radius: 5px;
        background-color: transparent;
        text-decoration: none;

        width: 100%;
        height: 4rem;

        margin: 0;

        color: $main-color;
        font-size: 1.3rem;
        text-shadow: 1px 1px 2px rgba(0, 0, 0, 0.3);
        -webkit-text-shadow: 1px 1px 2px rgba(0, 0, 0, 0.3);
        transition: 0.3s;
        box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);

        &:hover {
          color: $dark-color;
          background-color: $main-color;
        }

        &:active {
          color: $dark-color;
          border: 3px solid rgb(243, 243, 243);
          background-color: rgb(243, 243, 243);
          /* Fixed the typo here */
        }

        .writeus-icon {
          font-size: 1.8rem;
          margin-right: 0.5rem;
        }
      }

    }
  }
}

@media (min-width: 1025px) {
  .writeus-container {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;

    min-height: 20rem; /* Set a fixed minimum height */
    height: auto;    
    width: 100%;

    padding-left: 12.5%;
    padding-right: 12.5%;

    box-shadow: 0px 0px 11px -4px rgba(0, 0, 0, 0.75);
    -webkit-box-shadow: 0px 0px 11px -4px rgba(0, 0, 0, 0.75);
    -moz-box-shadow: 0px 0px 11px -4px rgba(0, 0, 0, 0.75);

    z-index: 1;
    background-color: $accent-color-second;

    .writeus-text-container {
      display: flex;
      flex-direction: column;
      justify-content: center;

      height: 80%;
      width: 60%;
      text-align: start;

      .writeus-title {
        color: $main-color;

        font-size: 2rem;
        font-weight: 700;
        font-family: $font-family;

        margin: 0;
        text-shadow: 1px 1px 2px rgba(0, 0, 0, 0.3);
        -webkit-text-shadow: 1px 1px 2px rgba(0, 0, 0, 0.3);

      }

      .writeus-text {
        color: $main-color;

        font-size: 0.8rem;
        font-weight: 500;
        font-family: $accent-font-family;

        margin: 0;
        margin-top: 1rem;
        text-shadow: 1px 1px 2px rgba(0, 0, 0, 0.3);
        -webkit-text-shadow: 1px 1px 2px rgba(0, 0, 0, 0.3);

      }
    }

    .writeus-button-container {
      display: flex;
      align-items: center;
      justify-content: center;
      height: 80%;
      width: 30%;
      margin: 0;

      .writus-button {
        text-decoration: none;
        cursor: pointer;
        display: flex;
        align-items: center;
        justify-content: center;

        border: 3px solid $main-color;
        border-radius: 5px;
        background-color: transparent;

        width: 100%;
        height: 4rem;

        margin: 0;

        color: $main-color;
        font-size: 1.3rem;
        text-shadow: 1px 1px 2px rgba(0, 0, 0, 0.3);
        -webkit-text-shadow: 1px 1px 2px rgba(0, 0, 0, 0.3);
        transition: 0.3s;
        box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);

        &:hover {
          color: $dark-color;
          background-color: $main-color;
        }

        &:active {
          color: $dark-color;
          border: 3px solid rgb(243, 243, 243);
          background-color: rgb(243, 243, 243);
          /* Fixed the typo here */
        }

        .writeus-icon {
          font-size: 1.8rem;
          margin-right: 0.5rem;
        }
      }

    }
  }
}

@media (min-width: 1400px) {
  .writeus-container {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;

    min-height: 20rem; /* Set a fixed minimum height */
    height: 0rem;
    width: 100%;

    padding-left: 12.5%;
    padding-right: 12.5%;

    box-shadow: 0px 0px 11px -4px rgba(0, 0, 0, 0.75);
    -webkit-box-shadow: 0px 0px 11px -4px rgba(0, 0, 0, 0.75);
    -moz-box-shadow: 0px 0px 11px -4px rgba(0, 0, 0, 0.75);

    z-index: 1;
    background-color: $accent-color-second;

    .writeus-text-container {
      display: flex;
      flex-direction: column;
      justify-content: center;

      height: 80%;
      width: 40%;
      text-align: start;

      .writeus-title {
        color: $main-color;

        font-size: 4rem;
        font-weight: 700;
        font-family: $font-family;

        margin: 0;
        text-shadow: 1px 1px 2px rgba(0, 0, 0, 0.3);
        -webkit-text-shadow: 1px 1px 2px rgba(0, 0, 0, 0.3);

      }

      .writeus-text {
        color: $main-color;

        font-size: 1rem;
        font-weight: 500;
        font-family: $accent-font-family;

        margin: 0;
        margin-top: 1rem;
        text-shadow: 1px 1px 2px rgba(0, 0, 0, 0.3);
        -webkit-text-shadow: 1px 1px 2px rgba(0, 0, 0, 0.3);

      }
    }

    .writeus-button-container {
      display: flex;
      align-items: center;
      justify-content: center;
      height: 90%;
      width: 25%;
      margin: 0;
      padding: 0;

      .writus-button {
        cursor: pointer;
        display: flex;
        align-items: center;
        justify-content: center;

        border: 3px solid $main-color;
        border-radius: 5px;
        background-color: transparent;

        width: 100%;
        height: 5rem;

        margin: 0;
        padding: 0;

        text-decoration: none;
        color: $main-color;
        font-size: 2rem;
        text-shadow: 1px 1px 2px rgba(0, 0, 0, 0.3);
        -webkit-text-shadow: 1px 1px 2px rgba(0, 0, 0, 0.3);
        transition: 0.3s;
        box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);

        &:hover {
          color: $dark-color;
          background-color: $main-color;
        }

        &:active {
          color: $dark-color;
          border: 3px solid rgb(243, 243, 243);
          background-color: rgb(243, 243, 243);
          /* Fixed the typo here */
        }

        .writeus-icon {
          margin: 0;
          padding: 0;
          font-size: 2rem;
          margin-right: 0.5rem;
          margin-top: 0.4rem;
        }
      }

    }
  }
}